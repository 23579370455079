import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import logo from "../../assets/logoNmk_blue.png";
//AMC: warning removal-> import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Auth.scss";
// @ts-ignore
import Flags from 'country-flag-icons/react/1x1'
//AMC: warning removal-> import {CONFIG_FILE, getConfigFile} from "../../types/ConfigFile";

export default function Login(props: { onSuccessfulLogin: Function, onSignInIntent: Function, onResetPassword: Function, onRevalidateMail: Function }) {
    const {t} = useTranslation();

    const [isPageShowed, setPageShowed] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isLanguageDropdown, setLanguageDropdown] = useState<boolean>(false);

    useEffect(() => {
        // Auto-login script:
        const refreshToken = localStorage.getItem('refreshToken');
        if (!refreshToken) {
            setPageShowed(true);
            return;
        }
        Api.refreshToken(refreshToken).then((value: any) => {
            if (value.status.status === 'ok' && value.access_token && value.refresh_token) {
                // Autologin
                login(value.access_token, value.refresh_token);
            } else {
                setPageShowed(true);
            }
        }).catch(() => setPageShowed(true));
    }, []);

    const tryLogin = () => {
        Api.getToken({email: email, password: password}).then(value => {
            if (value.status?.status === "ko"){
                if (value.status?.error[0] === "User mail not validated yet"){
                    props.onRevalidateMail(email);
                }
            }else{
                if (!value.access_token || !value.refresh_token) {
                    alert(t('errors.badUserOrPassword'));
                } else {
                    login(value.access_token, value.refresh_token);
                }
            }
        });
    }

    const login = (token: string, tokenRefresh: string) => {
        Api.token = token;
        localStorage.setItem('refreshToken', tokenRefresh);
        props.onSuccessfulLogin();
    }


    // const openSignInOwnerWebpage = () => {
    //     window.open(CONFIG_FILE.signInOwnerWeb, '_blank')?.focus();
    // }

    const flags: { component: JSX.Element; code: string; name: string }[] = [
        {
            component: <Flags.GB/>,
            name: 'English',
            code: 'en'
        },
        {
            component: <Flags.ES/>,
            name: 'Español',
            code: 'es'
        },
    ];

    const changeLanguage = (code: string) => {
        localStorage.setItem('language', code);
        window.location.reload();
    }

    if (!isPageShowed) {
        return null;
    }

    return (
        <div className="cardContainer auth">
            <div className="card">
                <div className="card-body">
                    <div id="languageSelector" className="form-select"
                            onClick={() => setLanguageDropdown(!isLanguageDropdown)}>
                        <div className="display">
                            {flags.find(flag => flag.code === localStorage.getItem('language'))?.component ?? null}
                        </div>
                        {isLanguageDropdown ?
                            <ul className="dropdown">
                                {flags.map(flag => <li onClick={() => changeLanguage(flag.code)}>{flag.component}{flag.name}</li>)}
                            </ul> : null}
                    </div>
                    <img src={logo} alt=""/>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">{t('user.email')}</label>
                        <input className="form-control" id="email" onChange={(event) => setEmail(event.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="password">{t('user.password')}</label>
                        <input className="form-control" id="password" type="password"
                                onChange={(event) => setPassword(event.target.value)}/>
                    </div>
                    <button className="btn btn-primary" onClick={() => tryLogin()}
                            style={{display: 'block', margin: '1rem auto 0.5rem auto'}}>
                        {t('actions.login')}
                    </button>
                </div>
                <div className="card-footer">
                    <div className="mb-1 tip">
                        {t('questions.noAccount')}
                    </div>
                    {/* Old sign in "as GUEST" and "as OWNER". Now it is only "sign in"
                    <button className="btn btn-light mb-1" onClick={() => props.onSignInIntent()}>
                        {t('actions.signInAsGuest_order')}...
                    </button>
                    <button className="btn btn-light mb-1" onClick={() => openSignInOwnerWebpage()}>
                        {t('actions.signInAsOwner_order')}...
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} style={{marginLeft: '12px'}}/>
                    </button>*/}
                    <button className="btn btn-light mb-1" onClick={() => props.onSignInIntent()}>
                        {t('actions.signIn_order')}...
                    </button>
                    <div className="mb-1 mt-2 tip">
                        {t('questions.passwordForgotten')}
                    </div>
                    <button className="btn btn-light mb-1" onClick={() => props.onResetPassword()}>
                        {t('actions.resetPass_order')}...
                    </button>
                </div>
            </div>
        </div>
    );
}
