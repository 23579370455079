import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {Device, Feature, Room} from "../../api/generated/Entities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Utils from "../../Utils";
//AMC: warning removal ->import deviceImage from "../../assets/entities/wikipedia_door.png";
//AMC: warning removal ->import gatewayImage from "../../assets/entities/random_router.png";
import EntityImage from "../misc/EntityImage";
//AMC: warning removal ->import {getKeyType, getKeyTypeFromFeature, getKeyTypeI18N, KeyTypes} from "../../types/KeyType";
import {getKeyTypeFromFeature, getKeyTypeI18N} from "../../types/KeyType";
import _ from "lodash";


export default function DeviceEdit(props: {
    id: number,
    onSave: () => void,
    onCancel: () => void
}) {
    const {t} = useTranslation();
    const [device, setDevice] = useState<Device>({category: 'Cerradura Inteligente'});
    const [uploadedImage, setUploadedImage] = useState<Blob | undefined>(undefined);
    const [rooms, setRooms] = useState<Room[]>([]);
    const [features, setFeatures] = useState<Feature[]>([]);

    useEffect(() => {
        if (props.id > 0) {
            Api.searchDevice({id: props.id}).then(value => {
                if (!Api.isError(value, 'mustBeArrayWithObject')) {
                    setDevice(value.content[0]);
                }
            })
        }
        Api.searchRoom().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setRooms(value.content);
            }
        });
        Api.getFeatures().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setFeatures(value.content);
            }
        });
    }, []);

    const save = async () => {
        if (device.category === 'Gateway') {
            delete device.features;
        }else{
            if (device.features == null || device.features.length < 1){
                alert(t('device.mustHaveFeature'));
                return;
            }
        }

        let saveResponse = await Api.setDevice(device);
        if (uploadedImage) {
            let uploadResponse = await Api.uploadImage('DEVICE', uploadedImage, saveResponse.content.device.iddevice);
            Api.isError(uploadResponse);
        }
        if (!Api.isError(saveResponse)) {
            props.onSave();
        }
        console.log(saveResponse);
        if (saveResponse.content.activation != null){
            alert(t('device.savedOkMessage') + ": "+ saveResponse.content.activation);
        }
    }

    const updateSelectedFeatures = (feature: Feature) => {
        let featuresClone = _.cloneDeep(device.features) ?? [];
        if (featuresClone.find(f => f.idfeature === feature.idfeature)) {
            featuresClone = featuresClone.filter(f => f.idfeature !== feature.idfeature);
        } else {
            featuresClone.push(feature);
        }
        setDevice({...device, features: featuresClone});
    }

    return <div>
        <div className="header">
            <EntityImage entityType="device" entity={device} shape="landscape73" customImage={device.image_filename}
                         onUploadNewImage={(imageFile) => setUploadedImage(imageFile)}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="deviceDescription">{t('device.description')}</label>
            <input className="form-control" id="deviceDescription" value={device?.nmk_description ?? ''}
                   onChange={(event) => setDevice({...device, nmk_description: event.target.value})}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="associatedRoom">{t('device.associatedRoom')}</label>
            <select className="form-select" id="associatedRoom" value={device?.room_idroom}
                    disabled={!!device.iddevice}
                    onChange={(event) => setDevice({
                        ...device,
                        room_idroom: parseInt(event.target.value)
                    })}>
                <option value="0">{t('none')}</option>
                {
                    rooms.map(room =>
                        <option value={room.idroom}>{room.name}</option>
                    )
                }
            </select>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="category">{t('device.category')}</label>
            <select className="form-select" id="category" value={device?.category}
                    disabled={!!device.iddevice}
                    onChange={(event) => setDevice({
                        ...device,
                        category: event.target.value as Device['category']
                    })}>
                <option value="Cerradura Inteligente">{t('device.categories.smartLock')}</option>
                <option value="Cerradura Gateway">{t('device.categories.lockGateway')}</option>
                <option value="Gateway">{t('device.categories.gateway')}</option>
                <option value="Dispositivo acceso">{t('device.categories.accessDevice')}</option>
            </select>
        </div>
        <div className={'mb-3 ' + (device.category === 'Gateway' ? 'd-none' : '')}>
            <label className="form-label">{t('device.featuresAvailable')}</label>
            <div className={'card flex-grow-1 btn-group-vertical'}>
                {
                    features.map(feature => {
                        return {
                            feature: feature,
                            keyType: getKeyTypeFromFeature(feature)
                        }
                    }).map(extendedFeature => {
                        return <button
                            className={'text-start text-nowrap btn ' + (device.features?.map(f => f.idfeature).includes(extendedFeature.feature.idfeature) ? 'btn-primary' : '')}
                            id={'feature' + extendedFeature.feature.name}
                            onClick={() => updateSelectedFeatures(extendedFeature.feature)}>
                            {extendedFeature.keyType?.icon} <span className={'ms-1 me-3'}>{getKeyTypeI18N(t, extendedFeature.keyType)}</span>
                        </button>
                    })
                }
            </div>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="deviceHardwareId">{t('device.hardwareId')}</label>
            <input className="form-control" id="deviceHardwareId" value={device?.hardware_id ?? ''}
                    onChange={(event) => setDevice({...device, hardware_id: event.target.value})}/>
        </div>
        <div className="d-flex justify-content-evenly">
            <button className="btn btn-primary" onClick={() => save()}>
                <FontAwesomeIcon icon={['fas', 'save']} size="lg"/>
                <span>{t('actions.save')}</span>
            </button>
            <button className="btn btn-secondary" onClick={() => props.onCancel()}>{t('actions.close')}</button>
        </div>
    </div>;
}

