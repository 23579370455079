import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import {CONFIG_FILE, getConfigFile} from "./types/ConfigFile";

getConfigFile().then(() => {
    // Add <script> to document to import google maps api, using CONFIG_FILE just loaded
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://maps.googleapis.com/maps/api/js?key=' + CONFIG_FILE.googleMapApiKey + '&callback=initMap';
    document.body.appendChild(s);

    // ================
    // Render React App
    // ================
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root')
    );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
