import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from "../../assets/logoNmk_blue.png";
import logoNomorepass from "../../assets/logoNomorepass_green.png"
import "./Auth.scss";
import Api from "../../api/Api";

export default function ResetPassword(props: { onBackToLogin: Function }) {
    const {t} = useTranslation();

    const [email, setEmail] = useState<string>('');

    const validateEmail = (email: string): boolean => {
//AMC:warning removal ->        const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
    }

    const showEmailError = (email: string): boolean => email !== '' && !validateEmail(email);

    const resetPass = () => {
        Api.requestNewPass(email).then(value => {
            if (!Api.isError(value)) {
                alert(t('user.resetEmailSent'));
                props.onBackToLogin();
            }
        })
    }

    return (
        <div className="cardContainer auth">
            <div className="card">
                <div className="card-body">
                    <img src={logo} alt=""/>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="email">{t('user.email')}</label>
                        <input className={'form-control ' + (showEmailError(email) ? 'is-invalid' : '')}
                               id="email"
                               onChange={(event) => setEmail(event.target.value)}
                        />
                        <div className="invalid-feedback">
                            {t('errors.badEmail')}
                        </div>
                    </div>
                    <button className="btn btn-primary" disabled={!validateEmail(email)}
                            onClick={() => resetPass()} style={{display: 'block', margin: '1rem auto 0.5rem auto'}}>
                        {t('actions.resetPass')}
                    </button>
                </div>
                <div className="card-footer">
                    <button className="btn btn-light"
                            onClick={() => props.onBackToLogin()}>
                        {t('actions.login_order')}...
                    </button>
                    <div className="mb-1 mt-3 text-center" id="nomorepass">
                        {t('nomorepass.tip')}
                        <img src={logoNomorepass} alt="" title={t('nomorepass.discover')}
                             onClick={() => window.open('https://nomorepass.com', '_blank')?.focus()}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
