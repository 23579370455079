export type EntityType = 'none' | 'key' | 'device' | 'room' | 'complex' | 'stay' | 'user';

export const isEntityType = (primaryView: string | undefined | null): primaryView is EntityType => {
    return primaryView === 'none' ||
        primaryView === 'key' ||
        primaryView === 'device' ||
        primaryView === 'room' ||
        primaryView === 'complex' ||
        primaryView === 'stay' ||
        primaryView === 'user';
}
