import {Key} from "../api/generated/Entities";

/**
 * Extract the name of the key depending of key type
 * @param key
 */
export const getKeyName = (key: Key | undefined): string => {
    if (!key) {
        return '';
    }
    if (key.user_or_key === 'key') {
        return key.description ?? '';
    } else if (key.user_or_key === 'user') {
        return key.nmk_user_description ?? '';
    } else {
        return key.description ?? key.nmk_user_description ?? '';
    }
}

export const setKeyName = (key: Key, name: string): Key => {
    name = name.substring(0, 40);
    if (key.user_or_key === 'key') {
        key.description = name;
        delete key.nmk_user_description;
    } else if (key.user_or_key === 'user') {
        key.nmk_user_description = name;
        delete key.description;
    }
    return key;
}
