import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {User} from "../../api/generated/Entities";
//AMC: warning removal ->import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityType} from "../../types/EntityType";
import {SecondaryView} from "../../types/SecondaryView";
import EntityImage from "../misc/EntityImage";
import ConfirmDeleteBox from "../misc/ConfirmDeleteBox";
import {GlobalFilters} from "../Panel";
import {ViewMode} from "../../types/PrimaryView";
import NewEntity from "../misc/NewEntity";


export default function Users(props: {
    update: number, // Increment to reload the list of entities
    setPrimaryView: (pv: EntityType) => void,
    setSecondaryView: (sv: SecondaryView) => void,
    globalFilters: GlobalFilters,
    viewMode: ViewMode
}) {
    const {t} = useTranslation();
    const [users, setUsers] = useState<Array<User>>([]);
    const [selectedToDelete, setSelectedToDelete] = useState<number[]>([]);

    const firstRun = useRef(true); // Prevent executing useEffect[props.update] on first render

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return
        }
        getUsers();
    }, [props.update]);

    const getUsers = () => {
        // region Workaround to find users
        // let promiseList: Promise<any>[] = [];
        // for (let i = 1; i < 100; i++) {
        //     promiseList.push(
        //         Api.searchUser({id: i})
        //     );
        // }
        // Promise.all(promiseList).then(values => {
        //     values = values.filter(value => Array.isArray(value?.content) && value?.content[0]);
        //     setUsers(values.map(value => value.content[0]));
        // })
        // endregion
        Api.getKnowUsers().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setUsers(value.content);
            }
        });
    }

    const getFilteredUsers = () => {
        return users
            .filter((user: User) => !props.globalFilters.user || user.iduser === props.globalFilters.user)
    }

    //AMC: warning removal ->
    // const toggleDelete = (stayId: number | undefined) => {
    //     if (!stayId) {
    //         return;
    //     }
    //     if (selectedToDelete.includes(stayId)) {
    //         setSelectedToDelete(selectedToDelete.filter(i => i !== stayId));
    //     } else {
    //         setSelectedToDelete([...selectedToDelete, stayId]);
    //     }
    // };

    const deleteUsers = () => {
        setSelectedToDelete([]);
        getUsers();
    };

    const getEntitiesContainer = () => {
        if (props.viewMode === 'listMode') {
            return <div className={'tableContainer'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        <th>{t('id')}</th>
                        <th>{t('user.email')}</th>
                        <NewEntity entity={'user'} onClick={() => props.setSecondaryView({entity: 'user', id: 0})}
                                   display={'table'}/>
                    </tr>
                    </thead>
                    <tbody>
                    {getFilteredUsers()
                        .map(user => <tr
                            className={selectedToDelete.includes(user.iduser ?? 0) ? 'rowSelectedToDelete' : ''}>
                            <td>{user.iduser}</td>
                            <td>{user.email}</td>
                            <td/>
                        </tr>)}
                    </tbody>
                </table>
            </div>;
        } else {
            return <div className="cardsContainer">
                <NewEntity entity={'user'} onClick={() => props.setSecondaryView({entity: 'user', id: 0})}
                           display={'card'}/>
                {getFilteredUsers()
                    .map(user => <div
                        className={'card ' + (selectedToDelete.includes(user.iduser ?? 0) ? 'cardSelectedToDelete' : '')}
                        key={user.iduser}>
                        <div className="card-header">
                            <EntityImage entityType="user" entity={user} shape="square" customImage={user.image_filename}/>
                        </div>
                        <div className="card-body">
                            <div className="cardTitle">
                                {user.email}
                            </div>
                            <table className="entityDataTable">
                                <tbody>
                                <tr>
                                    <td>{t('id')}</td>
                                    <td>{user.iduser}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        {/*<div className="card-footer">
                    <div className="btn-group btn-group-sm">
                        <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                onClick={() => props.setSecondaryView({entity: 'user', id: user.iduser})}>
                            <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                            <span>{t('actions.edit')}</span>
                        </button>
                        <button className="btn btn-sm btn-danger" title={t('actions.delete')}
                                onClick={() => toggleDelete(user.iduser)}>
                            <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                        </button>
                    </div>
                </div>*/}
                    </div>)}
            </div>;
        }
    }

    return <div className="primaryViewContainer">
        <ConfirmDeleteBox entityType={'user'} listOfSelectedEntities={selectedToDelete}
                          onCancel={() => setSelectedToDelete([])} onClickDeleteButton={() => deleteUsers()}/>
        <div className={'entitiesContainer'}>{getEntitiesContainer()}</div>
    </div>;
}

