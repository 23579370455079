import React from 'react';
import {useTranslation} from "react-i18next";
import "./Modal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityType} from "../../types/EntityType";
import "./ConfirmDeleteBox.scss";

export default function ConfirmDeleteBox(props: {
    listOfSelectedEntities: number[],
    entityType: EntityType,
    onCancel: Function,
    onClickDeleteButton: Function
}) {
    const {t} = useTranslation();

    const getEntityGender = () => {
        switch (props.entityType) {
            case "none":
                return {};
            case "key":
                return {context: 'female'};
            case "device":
                return {};
            case "room":
                return {context: 'female'};
            case "complex":
                return {};
            case "stay":
                return {context: 'female'};
            case "user":
                return {};
        }
    }

    return <div className={'confirmDeleteBox ' + (props.listOfSelectedEntities.length > 0 ? 'visible' : '')}>
        <div className={'confirmDeleteBoxText'}>{
            t('youHave') + ' ' + props.listOfSelectedEntities.length + ' ' +
            t('entity.' + props.entityType, {count: props.listOfSelectedEntities.length}).toLowerCase() + ' ' +
            t('selected', {count: props.listOfSelectedEntities.length, ...getEntityGender()}).toLowerCase()
        }</div>
        <button className={'btn btn-light btn-sm ms-3'} onClick={() => props.onClickDeleteButton()}>
            <FontAwesomeIcon icon={['fas', 'trash']}/>
            <span>{t('actions.delete')}</span>
        </button>
        <button className={'btn btn-light btn-sm ms-3'} onClick={() => props.onCancel()}>
            {t('actions.cancel')}
        </button>
    </div>;
}

