import React, {PropsWithChildren} from 'react';
import {useTranslation} from "react-i18next";
import "./Modal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoadingSpinner from "./LoadingSpinner";

export default function Modal(props: PropsWithChildren<{visible: boolean, isLoading?: boolean, title?: string, onClose?: Function}>) {
    const {t} = useTranslation();

    if (!props.visible) {
        return null;
    }

    return <div className={'modalContainer ' + (props.visible ? '' : 'd-none')}>
        <div className="card">
            {
                props.title ?
                    <div className="card-header">
                        <div className="title">{props.title}</div>
                        {
                            props.onClose ?
                                <div className="close">
                                    <button className="btn btn-sm btn-primary" title={t('actions.close')}
                                            onClick={() => props.onClose!()}>
                                        <FontAwesomeIcon icon={['fas', 'times']}/>
                                    </button>
                                </div> : null
                        }
                    </div> : null
            }
            <div className={'card-body ' + (props.isLoading ? 'd-flex justify-center' : '')}>
                {props.isLoading ? <LoadingSpinner/> : props.children}
            </div>
        </div>
    </div>;
}

