import React, {CSSProperties} from 'react';
import {useTranslation} from "react-i18next";
import "./LoadingSpinner.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function LoadingSpinner(props: {style?: CSSProperties}) {
    const {t} = useTranslation();
    return <div className="loadingSpinner" style={props.style}>
        <FontAwesomeIcon title={t('loading')} icon={['fas', 'hourglass-half']}/>
    </div>;
}

