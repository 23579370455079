import React, {ChangeEvent} from 'react';
import {useTranslation} from "react-i18next";
//AMC: warning removal ->import {getKeyType, KEYTYPE} from "../../types/KeyType";

export default function StringInput(props: {
    value: string,
    id: string,
    onChange: (value: ChangeEvent<HTMLInputElement>) => any,
    disabled?: boolean,
    placeholder?: string,
    className?: string
}) {
    const {t} = useTranslation();

    const showStringError = () => {
        console.log("showString error "+ props.value);
        if (!props.value) {
            return false;
        }
        let isError: boolean = false;
        // Todo: Check string length
        return isError;
    }

    return <input id={props.id} className={'form-control ' + (showStringError() ? 'is-invalid ' : ' ') + props.className}
                  value={props.value ?? ''} disabled={props.disabled}
                  placeholder={props.placeholder ?? 'Specify Key Name '}
                  onChange={(event) => props.onChange(event)}/>;
}

