import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {User} from "../../api/generated/Entities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EntityImage from "../misc/EntityImage";
//AMC: warning removal ->import {CONFIG_FILE} from "../../types/ConfigFile";


export default function UserEdit(props: {
    id: number,
    onSave: () => void,
    onCancel: () => void
}) {
    const {t} = useTranslation();
    const [user, setUser] = useState<User>({});
    const [uploadedImage, setUploadedImage] = useState<Blob | undefined>(undefined);

    useEffect(() => {
        if (props.id > 0) {
            Api.searchUser({id: props.id}).then(value => {
                if (!Api.isError(value, 'mustBeArrayWithObject')) {
                    setUser(value.content[0]);
                }
            })
        }
    }, []);

    const save = async () => {
        if (uploadedImage) {
            let uploadResponse = await Api.uploadImage('USER', uploadedImage);
            Api.isError(uploadResponse)
        }
        if (props.id > 0) {
            Api.updateUser(user).then(value => {
                if (!Api.isError(value, 'mustBeObject')) {
                    props.onSave();
                }
            });
        } else {
            Api.registerLocalUser(user, Api.token).then(value => {
                if (!Api.isError(value, 'mustBeObject')) {
                    props.onSave();
                }
            });
        }
    }

    return <div>
        <div className="header">
            <EntityImage entityType="user" entity={user} shape="square" customImage={user.image_filename}
                         onUploadNewImage={(imageFile) => setUploadedImage(imageFile)}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="localUserEmail">{t('Email')}</label>
            <input className="form-control" id="localUserEmail" value={user?.email}
                   disabled={!!props.id}
                   onChange={(event) => setUser({
                       ...user,
                       email: event.target.value
                   })}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="localUserPassword">{t('Contraseña nueva')}</label>
            <input className="form-control" id="localUserPassword"
                   value={user?.password} type={user?.password ? 'password' : 'text'}
                   onChange={(event) => setUser({
                       ...user,
                       password: event.target.value
                   })}/>
        </div>
        <div className="d-flex justify-content-evenly">
            <button className="btn btn-primary" onClick={() => save()}>
                <FontAwesomeIcon icon={['fas', 'save']} size="lg"/>
                <span>{t('actions.save')}</span>
            </button>
            <button className="btn btn-secondary" onClick={() => props.onCancel()}>{t('actions.close')}</button>
        </div>
    </div>;
}
