import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from "react-i18next";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import Keys from "./primary_views/Keys";
import "./Panel.scss";
import "./secondary_views/SecondaryView.scss";
import "./primary_views/PrimaryView.scss";
import Devices from "./primary_views/Devices";
import KeyEdit from "./secondary_views/KeyEdit";
import DeviceEdit from "./secondary_views/DeviceEdit";
import UserEdit from './secondary_views/UserEdit';
import Rooms from "./primary_views/Rooms";
import RoomEdit from "./secondary_views/RoomEdit";
import ComplexEdit from "./secondary_views/ComplexEdit";
import Complexes from "./primary_views/Complexes";
import Stays from "./primary_views/Stays";
import StayEdit from "./secondary_views/StayEdit";
import Users from "./primary_views/Users";
import SelectPromise from "./misc/SelectPromise";
import Api from "../api/Api";
import {getKeyTypeI18N, KeyTypes} from "../types/KeyType";
import {SecondaryView} from "../types/SecondaryView";
import {isPrimaryView, PrimaryView, ViewMode} from "../types/PrimaryView";
import {User} from "../api/generated/Entities";

export type GlobalFilters = {
    stay: number;
    key: number;
    device: number;
    room: number;
    complex: number;
    user: number;
    keyType: number;
    keyIncludeExpired: boolean;
    stayState: string;
    stayLocatorCode: string;
    stayDateFrom: string;
    stayDateTo: string;
    setStay: (value: (((prevState: number) => number) | number)) => void
    setKey: (value: (((prevState: number) => number) | number)) => void;
    setDevice: (value: (((prevState: number) => number) | number)) => void;
    setRoom: (value: (((prevState: number) => number) | number)) => void;
    setComplex: (value: (((prevState: number) => number) | number)) => void;
    setUser: (value: (((prevState: number) => number) | number)) => void;
    setKeyType: (value: (((prevState: number) => number) | number)) => void;
    setKeyIncludeExpired: (value: (((prevState: boolean) => boolean) | boolean)) => void;
    setStayState: (value: (((prevState: string) => string) | string)) => void;
    setStayLocatorCode: (value: (((prevState: string) => string) | string)) => void;
    setStayDateFrom: (value: (((prevState: string) => string) | string)) => void;
    setStayDateTo: (value: (((prevState: string) => string) | string)) => void;
};

export default function Panel() {
    const {t} = useTranslation();

    // Get last selected primary view
    let preSelectedPrimaryView: PrimaryView | null = null;
    if (isPrimaryView(localStorage.getItem('preSelectedPrimaryView'))) {
        preSelectedPrimaryView = localStorage.getItem('preSelectedPrimaryView') as PrimaryView;
        if (preSelectedPrimaryView === 'none') {
            preSelectedPrimaryView = null;
        }
    }

    // Get last selected grid mode
    let preSelectedViewMode: ViewMode | null = localStorage.getItem('preSelectedViewMode') as ViewMode | null;


    const [user, setUser] = useState<User | undefined>(undefined);
    const [primaryView, setPrimaryView] = useState<PrimaryView>(preSelectedPrimaryView ?? 'stay');
    const [secondaryView, setSecondaryView] = useState<SecondaryView>({entity: 'none', id: undefined});
    const [viewMode, setViewMode] = useState<ViewMode>(preSelectedViewMode ?? 'gridMode');

    // When a list must be reloaded, I add 1 to this number to update the primary view
    const [primaryViewUpdateNumber, setPrimaryViewUpdateNumber] = useState<number>(0);

    // IMPORTANT: Remember add to globalFilters object below and to GlobalFilters type
    const [filteredKey, setFilteredKey] = useState<number>(0);
    const [filteredStay, setFilteredStay] = useState<number>(0)
    const [filteredRoom, setFilteredRoom] = useState<number>(0);
    const [filteredComplex, setFilteredComplex] = useState<number>(0);
    const [filteredDevice, setFilteredDevice] = useState<number>(0);
    const [filteredKeyType, setFilteredKeyType] = useState<number>(0);
    const [filteredUser, setFilteredUser] = useState<number>(0);
    const [filteredKeyIncludeExpired, setFilteredKeyIncludeExpired] = useState<boolean>(false);
    const [filteredStayState, setFilteredStayState] = useState<string>("current");
    const [filteredStayLocatorCode, setFilteredStayLocatorCode] = useState<string>("");
    const [filteredStayDateFrom, setFilteredStayDateFrom] = useState<string>("");
    const [filteredStayDateTo, setFilteredStayDateTo] = useState<string>("");
    // IMPORTANT: Remember add to globalFilters object below and to GlobalFilters type


    const globalFilters: GlobalFilters = {
        key: filteredKey,
        setKey: setFilteredKey,
        stay: filteredStay,
        setStay: setFilteredStay,
        room: filteredRoom,
        setRoom: setFilteredRoom,
        complex: filteredComplex,
        setComplex: setFilteredComplex,
        device: filteredDevice,
        setDevice: setFilteredDevice,
        keyType: filteredKeyType,
        setKeyType: setFilteredKeyType,
        user: filteredUser,
        setUser: setFilteredUser,
        keyIncludeExpired : filteredKeyIncludeExpired,
        setKeyIncludeExpired : setFilteredKeyIncludeExpired,
        stayState: filteredStayState,
        setStayState: setFilteredStayState,
        stayLocatorCode: filteredStayLocatorCode,
        setStayLocatorCode: setFilteredStayLocatorCode,
        stayDateFrom: filteredStayDateFrom,
        setStayDateFrom: setFilteredStayDateFrom,
        stayDateTo: filteredStayDateTo,
        setStayDateTo: setFilteredStayDateTo
    };

    // Updates all PrimaryViews prop "update" to update their lists
    const reloadPrimaryView = () => {
        setPrimaryViewUpdateNumber(primaryViewUpdateNumber + 1);
    }

    const closeSecondaryView = () => {
        setSecondaryView({entity: 'none', id: undefined});
    }

    const logout = () => {
        localStorage.removeItem('refreshToken');
        window.location.reload();
    }

    const getMenuIcon = (text: PrimaryView): JSX.Element => {
        const menuIcons: { [name: string]: IconProp } = {
            key: ['fas', 'key'],
            device: ['fas', 'microchip'],
            room: ['fas', 'house-user'],
            complex: ['fas', 'city'],
            stay: ['fas', 'home'],
            user: ['fas', 'users'],
        }
        return <FontAwesomeIcon title="Edit" icon={menuIcons[text] ?? ['fas', 'bug']}/>;
    }

    const getPrimaryView = (): JSX.Element | null => {
        switch (primaryView) {
            case "none":
                return null;
            case 'key':
                return <Keys update={primaryViewUpdateNumber}
                            setPrimaryView={setPrimaryView} setSecondaryView={setSecondaryView}
                            globalFilters={globalFilters} viewMode={viewMode}/>;
            case 'device':
                return <Devices update={primaryViewUpdateNumber}
                            setPrimaryView={setPrimaryView} setSecondaryView={setSecondaryView}
                            globalFilters={globalFilters} viewMode={viewMode}/>;
            case 'room':
                return <Rooms update={primaryViewUpdateNumber}
                            setPrimaryView={setPrimaryView} setSecondaryView={setSecondaryView}
                            globalFilters={globalFilters} viewMode={viewMode}/>;
            case 'complex':
                return <Complexes update={primaryViewUpdateNumber}
                            setPrimaryView={setPrimaryView} setSecondaryView={setSecondaryView}
                            globalFilters={globalFilters} viewMode={viewMode}/>;
            case 'stay':
                return <Stays update={primaryViewUpdateNumber}
                            setPrimaryView={setPrimaryView} setSecondaryView={setSecondaryView}
                            globalFilters={globalFilters} viewMode={viewMode}/>;
            case 'user':
                return <Users update={primaryViewUpdateNumber}
                            setPrimaryView={setPrimaryView} setSecondaryView={setSecondaryView}
                            globalFilters={globalFilters} viewMode={viewMode}/>;
        }
    }

    useEffect(() => {
        Api.getMe().then(value => {
            if (Api.isError(value, 'mustBeArrayWithObject')) {
                alert('User not found');
                logout();
            }
            setUser(value.content[0]);
        })
    }, []);

    useEffect(() => {
        localStorage.setItem('preSelectedPrimaryView', primaryView);
    }, [primaryView]);

    useEffect(() => {
        localStorage.setItem('preSelectedViewMode', viewMode);
    }, [viewMode]);

    useEffect(() => {
        reloadPrimaryView();
    }, [filteredKey, filteredStay, filteredRoom, filteredDevice]);

    const getSecondaryView = (): JSX.Element | null => {
        const closeSecondaryViewAndReloadPrimaryView = () => {
            closeSecondaryView();
            reloadPrimaryView();
        }
        let stringifyedExtras = undefined;
        try {
            stringifyedExtras = JSON.stringify(secondaryView.extras);
        } catch (e) {

        }
        const key = secondaryView.entity + secondaryView.id + (stringifyedExtras ?? '');
        switch (secondaryView.entity) {
            case 'key':
                return <KeyEdit id={secondaryView.id ?? 0} key={key}
                                onSave={closeSecondaryViewAndReloadPrimaryView} onCancel={closeSecondaryView}/>;
            case 'device':
                return <DeviceEdit id={secondaryView.id ?? 0} key={key}
                                onSave={closeSecondaryViewAndReloadPrimaryView} onCancel={closeSecondaryView}/>;
            case 'room':
                return <RoomEdit id={secondaryView.id ?? 0} key={key}
                                onSave={closeSecondaryViewAndReloadPrimaryView} onCancel={closeSecondaryView}/>;
            case 'complex':
                return <ComplexEdit id={secondaryView.id ?? 0} key={key}
                                onSave={closeSecondaryViewAndReloadPrimaryView} onCancel={closeSecondaryView}/>;
            case 'stay':
                return <StayEdit id={secondaryView.id ?? 0} key={key} overridedRoom={secondaryView.extras?.roomId}
                                onSave={reloadPrimaryView} onCancel={closeSecondaryView}
                                setPrimaryView={setPrimaryView}
                                globalFilters={globalFilters}/>;
            case 'user':
                return <UserEdit id={secondaryView.id ?? 0} key={key}
                                onSave={closeSecondaryViewAndReloadPrimaryView} onCancel={closeSecondaryView}/>;
            case "none":
            default:
                return null;
        }
    }

    const getPrimaryTools = (): JSX.Element[] | null => {
        const getNumericFilter = (name: string, state: number, setState: React.Dispatch<React.SetStateAction<number>>) => {
            return <div className={'input-group input-group-sm ' + (state ? 'borderDance' : '')}>
                <span className="input-group-text">{name}</span>
                <input type="number" className="form-control form-control-sm"
                        value={state === 0 ? '' : state}
                        onChange={(event) => {
                            setState(!isNaN(parseInt(event.target.value)) ? parseInt(event.target.value) : 0)
                        }}
                        onClick={() => setState(0)}/>
            </div>;
        }
        const getBooleanFilter = (name: string, state: boolean, setState: React.Dispatch<React.SetStateAction<boolean>>) => {
            return (
                <div className = {'input-group input-group-sm ' + (state ? 'borderDance' : '')}>
                    <span className="input-group-text">{name}</span>
                    <input type="checkbox" 
                        className="form-control form-control-sm"
                        checked={state === true}
                        onChange={(event) => {
                            setState(!state)
                        }}
                        />
                </div>)
                ;            
        }
        const getStayStatesFilter = (state: string, setState: React.Dispatch<React.SetStateAction<string>>) => {
            return (
                <div className = {'input-group input-group-sm' + (state? 'borderDance' : '')}>
                    <select className="form-select form-select-sm"
                        value={state}
                        onChange={(event) =>{
                            setState(event.target.value);
                            reloadPrimaryView();
                        }}
                        >
                        <option value='all'>{t('stay.state.all')}</option>
                        <option value='past'>{t('stay.state.past')}</option>
                        <option value='current'>{t('stay.state.current')}</option>
                        <option value='future'>{t('stay.state.future')}</option>
                        <option value='valid'>{t('stay.state.valid')}</option>
                    </select>
                </div>
            );
        }
        const getStringFilter = (name:string, state: string, setState: React.Dispatch<React.SetStateAction<string>>) => {
            return (
                <div className={'input-group input-group-sm ' + (state ? 'borderDance' : '')}>
                    <span className="input-group-text">{name}</span>
                    <input type="text" className="form-control form-control-sm"
                        value={state === undefined ? '' : state}
                        size = {6}
                        onChange={(event) => {
                            setState(event.target.value);
                            reloadPrimaryView();
                        }}
                        onClick={() => {
                            setState('');
                            reloadPrimaryView();
                            }
                        }/>
                </div>
            );
        }
        const getDateStringFilter = (name:string, state: string, setState: React.Dispatch<React.SetStateAction<string>>) => {
            return (
                <div className={'input-group input-group-sm ' + (state ? 'borderDance' : '')}>
                    <span className="input-group-text">{name}</span>
                    <input type="date" className="form-control form-control-sm"
                        value={state === undefined ? '' : state}
                        size = {6}
                        onChange={(event) => {
                            setState(event.target.value);
                        }}
                        onClick={() => {
                            setState('');
                            }
                        }/>
                </div>
            );
        }        
        const getDoFilterButton = (name:string) => {
            return(
                <div className={'input-group input-group-sm '}>
                    
                    <span className="input-group-text"
                        onClick={() => {
                            reloadPrimaryView();
                            }
                        }                    
                    >{name}</span>
                </div>

            )
        }
        switch (primaryView) {
            case "none":
                return null;
            case 'key':
                return [
                    getBooleanFilter("Show expired", filteredKeyIncludeExpired, setFilteredKeyIncludeExpired),
                    getNumericFilter(t('entity.key'), filteredKey, setFilteredKey),
                    getNumericFilter(t('entity.stay'), filteredStay, setFilteredStay),
                    <div className={'input-group input-group-sm ' + (filteredKeyType !== 0 ? 'borderDance ' : ' ')}>
                        <label className="input-group-text">{t('type')}</label>
                        <select className={'form-select form-select-sm'} value={filteredKeyType}
                                onChange={(event) => setFilteredKeyType(parseInt(event.target.value))}>
                            <option value="0">{t('all')}</option>
                            {
                                KeyTypes.map(type => {
                                    return <option value={type.id} key={type.id}>{getKeyTypeI18N(t, type)}</option>
                                })
                            }
                        </select>
                    </div>,
                    <SelectPromise apiPromise={Api.searchRoom} idName="idroom" nameName="name"
                                    defaultValue={t('all')} labelName={t('entity.room')} isSmall={true}
                                    value={filteredRoom}
                                    onChange={value => setFilteredRoom(value)}/>,
                    <SelectPromise apiPromise={Api.searchDevice} idName="iddevice" nameName="nmk_description"
                                    defaultValue={t('all')} labelName={t('entity.device')} isSmall={true}
                                    value={filteredDevice}
                                    onChange={value => setFilteredDevice(value)}/>,
                ];
            case 'device':
                return [
                    getNumericFilter(t('entity.device'), filteredDevice, setFilteredDevice),
                    <SelectPromise apiPromise={Api.searchRoom} idName="idroom" nameName="name"
                                    defaultValue={t('all')} labelName={t('entity.room')} isSmall={true}
                                    value={filteredRoom}
                                    onChange={value => setFilteredRoom(value)}/>
                ];
            case 'room':
                return [
                    getNumericFilter(t('entity.room'), filteredRoom, setFilteredRoom),
                    <SelectPromise apiPromise={Api.searchComplex} idName="idcomplex" nameName="name"
                                    labelName={t('entity.complex')} isSmall={true}
                                    defaultValue={t('all')}
                                    value={filteredComplex}
                                    onChange={value => setFilteredComplex(value)}/>
                ];
            case 'complex':
                return [
                    getNumericFilter(t('entity.complex'), filteredComplex, setFilteredComplex)
                ];
            case 'stay':
                return [
                    getStayStatesFilter(filteredStayState, setFilteredStayState),
                    getStringFilter(t('stay.locatorCode'), filteredStayLocatorCode, setFilteredStayLocatorCode),
                    <SelectPromise apiPromise={Api.searchRoom} idName="idroom" nameName="name"
                                    defaultValue={t('all')} labelName={t('entity.room')} isSmall={true}
                                    value={filteredRoom}
                                    onChange={value => setFilteredRoom(value)}/>,
                    getDateStringFilter(t('time.from'), filteredStayDateFrom, setFilteredStayDateFrom),
                    getDateStringFilter(t('time.to'), filteredStayDateTo, setFilteredStayDateTo),
                    getDoFilterButton(t('actions.filterDates'))
                ];
            case 'user':
                return [
                    getNumericFilter(t('entity.user'), filteredUser, setFilteredUser)
                ];
        }
    }

    return (
        <div id="panel">
            {/*==============================*/}
            {/*==========Header==============*/}
            {/*==============================*/}
            <div id="header">
                <div id="headerTitle">
                    No more keys
                </div>
                <div id="viewMode" className={'btn-group'}>
                    <button className={'btn btn-sm ' + (viewMode === 'gridMode' ? 'btn-light' : 'btn-primary')}
                            onClick={() => setViewMode('gridMode')} title={t('viewMode.grid')}>
                        <FontAwesomeIcon icon={['fas', 'th']} size="lg"/>
                    </button>
                    <button className={'btn btn-sm ' + (viewMode === 'gridMode' ? 'btn-primary' : 'btn-light')}
                            onClick={() => setViewMode('listMode')} title={t('viewMode.list')}>
                        <FontAwesomeIcon icon={['fas', 'th-list']} size="lg"/>
                    </button>
                </div>
                <div id="headerTools">{getPrimaryTools()}</div>
                <div id="globalButtons">
                    <button onClick={() => setSecondaryView({entity: 'user', id: user?.iduser})}
                            className="btn btn-light btn-sm"
                            title={t('actions.edit') + ' ' + t('myProfile').toLowerCase()}>
                        <FontAwesomeIcon icon={['fas', 'user-circle']} size="lg"/>
                        <span>{t('myProfile')}</span>
                    </button>
                    <button onClick={() => logout()} className="btn btn-light btn-sm">
                        <FontAwesomeIcon className="doorOpen" icon={['fas', 'door-open']} size="lg"/>
                        <FontAwesomeIcon className="doorClosed" icon={['fas', 'door-closed']} size="lg"/>
                        <span>{t('actions.logout')}</span>
                    </button>
                </div>
            </div>
            <div id="body">
                {/*==============================*/}
                {/*==========Menu panel==========*/}
                {/*==============================*/}
                <div id="nav">
                    {['stay', 'key', 'device', 'room', 'complex', 'user'].map((text, index) => (
                        <div key={text} onClick={() => {
                            console.log("Mapeando "+ text);
                            setPrimaryView(text as PrimaryView);
                        }}
                            data-selected={primaryView === text}>
                            {getMenuIcon(text as PrimaryView)}
                            <div>{t('entity.' + text, {count: 2})}</div>
                        </div>
                    ))}
                </div>

                {/*====================================*/}
                {/*============Primary View============*/}
                {/*====================================*/}
                <div id="primaryView" className={viewMode}>
                    {getPrimaryView()}
                </div>

                {/*==========================================*/}
                {/*==============Secondary View==============*/}
                {/*==========================================*/}
                {secondaryView.entity !== 'none' ?
                    <div id="secondaryView">
                        {getSecondaryView()}
                    </div> : null}
            </div>
        </div>
    );
}
