import React, {ChangeEvent} from 'react';

export default function IntegerInput(props: {
    value: string,
    id: string,
    onChange: (value: ChangeEvent<HTMLInputElement>) => any,
    disabled?: boolean,
    placeholder?: string,
    className?: string
}) {
    
    const showIntegerError = () => {
        if (!props.value) {
            return false;
        }
        const code = props.value;
        let isError: boolean = false;
        if (!(new RegExp('^[0-9]+$')).test(code)) {
            isError = true;
        }
        return isError;
    }

    return <input id={props.id} className={'form-control ' + (showIntegerError() ? 'is-invalid ' : ' ') + props.className}
                    value={props.value ?? ''} disabled={props.disabled}
                    placeholder={props.placeholder ?? '#'}
                    onChange={(event) => props.onChange(event)}/>;
}

