import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {Device, Key, Room, Stay, User} from "../../api/generated/Entities";
import {ExtendedKey} from "../../api/ExtendedEntities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Utils from "../../Utils";
import EntityImage from "../misc/EntityImage";
import "./StayEdit.scss";
import {
    getKeyType,
    getKeyTypeFromFeature,
    getKeyTypeI18N,
    getUrlNomorepassGetShared,
    KEYTYPE,
    KeyTypes,
    setKeyType
} from "../../types/KeyType";
import {getKeyName, setKeyName} from "../../types/KeyName";
import ModalKeyQR from "../misc/ModalKeyQR";
import LoadingSpinner from "../misc/LoadingSpinner";
import NfcInput from "../misc/NfcInput";
import StringInput from "../misc/StringInput";
import IntegerInput from "../misc/IntegerInput";
import _ from "lodash";
import GeolocationMap, {Geolocation} from "../misc/GeolocationMap";
import {EntityType} from "../../types/EntityType";
import {GlobalFilters} from "../Panel";

type DeviceWithKey = { device: Device, keyType: KEYTYPE, nfc?: string | undefined, padKey?: string | undefined, keyName?: string | undefined };
type QrKeyTypeCredentials = { user: string, plainPassword: string, md5Password: string };
type NoSavedKey = { key: ExtendedKey, user: User, qrKeyTypeCredentials: QrKeyTypeCredentials | undefined };
type SavedKey = NoSavedKey & { qr: string };

export default function StayEdit(props: {
    id: number,
    overridedRoom?: number,
    onSave: () => void,
    onCancel: () => void,
    setPrimaryView: (pv: EntityType) => void,
    globalFilters: GlobalFilters
}) {
    const {t} = useTranslation();
    const [isSaving, setSaving] = useState(false);
    const [stay, setStay] = useState<Stay>({});
    const [rooms, setRooms] = useState<Room[]>([]);
    const [devicesWithKey, setDevicesWithKey] = useState<DeviceWithKey[]>([]);
    const [isGeolocationChecked, setGeolocationChecked] = useState<boolean>(false);
    const [geolocation, setGeolocation] = useState<Geolocation | undefined>(null);
    const [knowUsers, setKnowUsers] = useState<User[]>([]);
    const [userGuestEmail, setUserGuestEmail] = useState<string>('');
    const [userGuestEmailList, setUserGuestEmailList] = useState<string[]>([]);
    const [listOfSavedKeys, setListOfSavedKeys] = useState<SavedKey[]>([]);
    const [sendByEmail, setSendByEmail] = useState<boolean>(true)

    useEffect(() => {
        if (props.id > 0) {
            getStay(true);
        }
        Api.searchRoom().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setRooms(value.content);
            }
        });
        Api.getKnowUsers().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setKnowUsers(value.content);
            }
        });
    }, []);

    const getStay = (isFirstLoad: boolean, stayId?: number) => {
        Api.searchStay({id: stayId ?? props.id}).then(value => {
            if (!Api.isError(value, 'mustBeArrayWithObject')) {
                setStay(value.content[0]);
                if (isFirstLoad) {
                    setUserGuestEmailList(value.content[0].info_guests?.map((guest: User) => guest.email) ?? []);
                }
            }
        });
    }

    const reload = (stayId: number) => {
        // Reload stay
        getStay(false, stayId);

        // Clean selected key types of devices
        let devicesWithKeyClone = _.cloneDeep(devicesWithKey);
        devicesWithKeyClone.forEach(dwk => {
            dwk.keyType = KEYTYPE.INDETERMINATE;
            delete dwk.nfc;
        })
        setDevicesWithKey(devicesWithKeyClone);
    }

    useEffect(() => {
        if (props.overridedRoom && !stay.idstay) {
            stay.room_idroom = props.overridedRoom;
            setStay(stay);
        }
    }, []);

    useEffect(() => {
        if (stay.room_idroom) {
            Api.searchDevice().then(value => {
                if (!Api.isError(value, 'mustBeArray')) {
                    let devices = value.content.filter((device: Device) =>
                        device.room_idroom === stay.room_idroom && 
                        (device.category === 'Cerradura Inteligente' || device.category === 'Cerradura Gateway' || device.category === 'Dispositivo acceso'));
                    setDevicesWithKey(devices.map((device: Device) => {
                        return {device: device, keyType: 0}
                    }));
                }
            });
        } else {
            setDevicesWithKey([]);
        }
    }, [stay.room_idroom]);

    const getCreatedKeys = (roomFilter?: number, deviceFilter?: number): { key: Key, guest: User }[] => {
        if (!stay.info_guests) {
            return [];
        }
        let keyList: { key: Key, guest: User }[] = [];
        stay.info_guests.forEach(guest => guest.info_keys?.forEach(key => {
            if (!key || !key.device_iddevice || !guest) {
                return;
            }
            // Filter by device
            if (deviceFilter && deviceFilter !== key.device_iddevice) {
                return;
            }
            // Filter by devices of the room
            if (roomFilter) {
                let deviceIds: number[] = devicesWithKey.filter(d => d.device.room_idroom === roomFilter).map(dwk => dwk.device.iddevice!);
                if (!deviceIds.includes(key.device_iddevice)) {
                    return;
                }
            }
            keyList.push({key: key, guest: guest});
        }));
        return keyList;
    }

    
    const updateDevicesWithKey = (deviceId: number | undefined, typeId: number, nfc?: string | undefined) => {
        if (!deviceId) {
            return;
        }
        // Deep clone:
        let devicesCompoundClone: DeviceWithKey[] = _.cloneDeep(devicesWithKey);
        let deviceWithKey = devicesCompoundClone.find(compound => compound.device.iddevice === deviceId);
        if (!deviceWithKey) {
            return;
        }

        // Starting merge:
        if (nfc) { // Change nfc string
            deviceWithKey.nfc = nfc;
        } else if (deviceWithKey.keyType === typeId) {
            deviceWithKey.keyType = 0; // Deselect clicked key type
        } else {
            deviceWithKey.keyType = typeId; // Select new key type
        }

        // Set new array
        setDevicesWithKey(devicesCompoundClone);
    };

    const updateDevicesWithKey_PadKey = (deviceId: number | undefined, padKey: string | undefined) => {
        if (!deviceId) {
            return;
        }
        // Deep clone:
        let devicesCompoundClone: DeviceWithKey[] = _.cloneDeep(devicesWithKey);
        let deviceWithKey = devicesCompoundClone.find(compound => compound.device.iddevice === deviceId);
        if (!deviceWithKey) {
            return;
        }

        // Starting merge:
        deviceWithKey.padKey = padKey ?? '';

        // Set new array
        setDevicesWithKey(devicesCompoundClone);
    };
    const updateDevicesWithKey_KeyName = (deviceId: number | undefined, keyName: string) => {
        if (!deviceId) {
            return;
        }
        // Deep clone:
        let devicesCompoundClone: DeviceWithKey[] = _.cloneDeep(devicesWithKey);
        let deviceWithKey = devicesCompoundClone.find(compound => compound.device.iddevice === deviceId);
        if (!deviceWithKey) {
            return;
        }
        console.log("Updating device key keyname to "+ keyName);

        deviceWithKey.keyName = keyName;

        // Set new array
        setDevicesWithKey(devicesCompoundClone);
    };

    const validateEmail = (email: string): boolean => {
        const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
    }

    const showEmailError = (email: string): boolean => email !== '' && !validateEmail(email);

    const save = async () => {
        // Advice if no email is entered
        if (!userGuestEmailList || userGuestEmailList.length === 0) {
            if (!window.confirm(t('stay.confirmCreateWithoutEmails'))) {
                return;
            }
        }

        // Advice if email in input text is not added to list
        if (userGuestEmail && !userGuestEmailList.includes(userGuestEmail)) {
            if (!window.confirm(t('stay.confirmInputEmailNotAdded'))) {
                return;
            }
        }

        // Get devices in the room of the new stay
        if (devicesWithKey.filter(dwk => dwk.keyType).length === 0) {
            if (!window.confirm(t('stay.confirmCreateWithoutKeys'))) {
                return;
            }
        }

        // Start doing async things
        setSaving(true);

        // Create stay
        let stayResponse = await Api.setStay(stay);
        if (Api.isError(stayResponse, 'mustBeObject')) {
            // Alert created by Api.isError
            setSaving(false);
            return;
        }

        // Try to register all users by the emails provided in the form
        let registersResponse = await Promise.all(userGuestEmailList.map(email => Api.registerLocalUser({email: email}, Api.token)));
        let users: User[] = registersResponse
            .filter(rr => !Api.isError(rr, 'mustBeObject', false))
            .filter(rr => rr.content)
            .map(rr => rr.content)
            .filter(user => user.iduser);
        if (users.length === 0) {
            setSaving(false);
            props.onSave();
            reload(stayResponse.content.idstay);
            console.log('Created stay without users!!');
            return;
        }

        // Pair the users to the stay
        let pairResponses1 = await Promise.all(users.map(user => Api.pairGuestToStay(stayResponse.content.idstay, user.iduser!)));
        if (pairResponses1.find(pairResponse => Api.isError(pairResponse, 'mustBeObject'))) {
            // Alert created by Api.isError
            setSaving(false);
            return;
        }

        // Create one key for each device for each user of stay
        let noSavedKeys: Array<NoSavedKey> = [];
        for (let deviceWithKey of devicesWithKey) {
            if (!deviceWithKey.keyType) { // If the device hasn't any key type assigned:
                continue;
            }
            for (let user of users) {
                // Basic key data:
                let key: ExtendedKey = {};
                key = setKeyType(key, deviceWithKey.keyType);
                key = setKeyName(key, deviceWithKey.keyName ? deviceWithKey.keyName:t('entity.key') + ' ' +
                    t('of').toLowerCase() + ' ' +
                    rooms.find(r => r.idroom === deviceWithKey.device.room_idroom)?.name);

                key.device_iddevice = deviceWithKey.device.iddevice;
                key.ex_device = deviceWithKey.device;
                key.nmk_datefrom = stay.date_start;
                key.nmk_dateto = stay.date_end;

                // Set key special data:
                if (deviceWithKey.keyType === KEYTYPE.NFC) {
                    key.nmk_key_str = deviceWithKey.nfc;
                }
                if (deviceWithKey.keyType === KEYTYPE.PADKEY){
                    key.nmk_key_str = deviceWithKey.padKey;
                }
                if (isGeolocationChecked && geolocation &&
                    (deviceWithKey.keyType === KEYTYPE.REMOTE || deviceWithKey.keyType === KEYTYPE.QR)) {
                    key.nmk_geo = JSON.stringify(geolocation);
                }
                let qrKeyTypeCredentials: QrKeyTypeCredentials | undefined = undefined;
                if (deviceWithKey.keyType === KEYTYPE.QR) {
                    let user: string = Utils.generateAlphanumericToken(14);
                    let password: string = Utils.generateAlphanumericToken(14);
                    key.nmk_user_username = user;
                    key.nmk_user_password = CryptoJS.MD5(password).toString(); // Backend needs MD5 password
                    qrKeyTypeCredentials = {
                        user: user,
                        plainPassword: password,
                        md5Password: CryptoJS.MD5(password).toString()
                    }
                }

                // Push key to pile:
                noSavedKeys.push({key: key, user: user, qrKeyTypeCredentials: qrKeyTypeCredentials});
            }
        }

        // Save all keys in database and show alert if some response is bad
        let keyResponses = await Promise.all(noSavedKeys.map(k => Api.setKey(k.key).then(value => {
            if (value.content != null){
                value.content.ex_device = (k.key as ExtendedKey).ex_device;
            }
            return value;
        })));
        if (keyResponses.find(keyResponse => Api.isError(keyResponse))) {
            // Alert created by Api.isError
            setSaving(false);
            return;
        }

        // Put keys with theirs qrcodes into listOfKeysGenerated state to show 1 by 1 MODALS with each key or SEND BY EMAIL
        // ---------------------------------------------------------------------------======------------------=============
        let savedKeys: SavedKey[] = keyResponses.map((response, index) => {
            return {
                // Put data from backend:
                key: response?.content,
                qr: response?.qrcode, // Only comes if remote key type. If it isn't remote, then I find it with nomorepass
                // Recover data from noSavedKeys:
                user: noSavedKeys[index]?.user,
                qrKeyTypeCredentials: noSavedKeys[index]?.qrKeyTypeCredentials
            };
        });

        // Pair the created keys to the guest user
        let pairResponses2 = await Promise.all(savedKeys.map(k => Api.pairGuestToKey(k.key.idkey!, k.user.iduser!)));
        if (pairResponses2.find(pairResponse => Api.isError(pairResponse, 'mustBeObject'))) {
            // Alert created by Api.isError
            setSaving(false);
            return;
        }

        // Pair the created keys to the stay
        let pairResponses3 = await Promise.all(savedKeys.map(k => Api.pairStayToKey(stayResponse.content.idstay, k.key.idkey!)));
        if (pairResponses3.find(pairResponse => Api.isError(pairResponse, 'mustBeObject'))) {
            // Alert created by Api.isError
            setSaving(false);
            return;
        }

        // NFC keys doesn't need QR popup, so I removed them:
        savedKeys = savedKeys.filter(k => k.key && getKeyType(k.key)?.id !== KEYTYPE.NFC)

        // Fill qr of all keys. Only remote keys have qr already
        for (let savedKey of savedKeys) {
            // Get qr of 'key' key types: sound, light and bluetooth (nfc doesn't have popup)
            if (getKeyType(savedKey.key)?.userOrKey === 'key') {
                if (!savedKey.key.nmk_key_str) {
                    alert('Error no nmk_key_str received');
                    continue;
                }
                let qrCode = await Api.getNomorepassKeyQR(savedKey.key, savedKey.key.nmk_key_str);
                if (typeof qrCode === 'string') {
                    savedKey.qr = qrCode;
                } else {
                    alert('Error getting the nomorepass QR code');
                }
            }
            // Get qr of 'qr' key type
            if (getKeyType(savedKey.key)?.id === KEYTYPE.QR) {
                if (!savedKey.qrKeyTypeCredentials) {
                    alert('Error no user credentials generated');
                    continue;
                }
                let qrCode = await Api.getNomorepassUserPasswordQR(savedKey.key, savedKey.qrKeyTypeCredentials.user, savedKey.qrKeyTypeCredentials.plainPassword);
                if (typeof qrCode === 'string') {
                    savedKey.qr = qrCode;
                } else {
                    alert('Error getting the nomorepass QR code');
                }
            }
        }

        // Finally show popups or send emails:
        if (savedKeys.length > 0) {
            if (sendByEmail) {
                // Send emails
                let sendResponse = await Api.sendGuestsCredentials(stayResponse.content.idstay, savedKeys.map(sk => {
                    return {idguest: sk.user.iduser!, credentialString: getUrlNomorepassGetShared(sk.qr)}
                }));
                if (Api.isError(sendResponse, undefined, false)) {
                    alert('Error sending emails');
                    setSaving(false);
                    return;
                }
                const totalEmails = sendResponse.content.sent_ok + sendResponse.content.sent_ko;
                alert(t('stay.emailsSent') + ': ' + sendResponse.content.sent_ok + '/' + totalEmails);
            } else {
                // Show popups
                setListOfSavedKeys(savedKeys);
            }
        }
        props.onSave();
        reload(stayResponse.content.idstay);
        setSaving(false);
    }

    const deleteKeys = async (keyList: { key: Key, guest: User }[]) => {
        if (!stay.idstay) {
            alert('idstay not defined');
            return
        }

        // Filter undefined values
        keyList = keyList.filter(compound => compound.key?.idkey && compound.guest?.iduser);

        // Unpair keys from users
        let unpairResponse1 = await Promise.all(keyList.map(compound => Api.unpairGuestFromKey(compound.key.idkey!, compound.guest.iduser!)));
        unpairResponse1.map(unpairResponse => Api.isError(unpairResponse));

        // Unpair keys from stay
        let unpairResponse2 = await Promise.all(keyList.map(compound => Api.unpairStayFromKey(stay.idstay!, compound.key.idkey!)));
        unpairResponse2.map(unpairResponse => Api.isError(unpairResponse));

        // Remove keys
        let deletesResponse: any = await Api.deleteKeys(keyList.map(compound => compound.key.idkey!));
        deletesResponse.map((deleteResponse: any) => Api.isError(deleteResponse));

        // Reload stay
        getStay(false);
    };

    const deleteKeysOfRoom = async (roomId: number | undefined) => {
        if (!roomId) {
            return;
        }
        if (window.confirm(t('stay.changeRoomConfirm'))) {
            setSaving(true);
            await deleteKeys(getCreatedKeys(roomId));
            setSaving(false);
        }
    }


    const deleteGuest = (guestId: number) => {
        if (!stay.idstay) {
            console.log('Undefined stay.idstay');
            return;
        }
        Api.unpairGuestFromStay(stay.idstay, guestId).then(value => {
            Api.isError(value);
            getStay(false);
        });
    };

    const goToKey = (keyId: number | undefined) => {
        if (keyId) {
            props.setPrimaryView('key');
            props.globalFilters.setKey(keyId);
            props.globalFilters.setDevice(0);
            props.globalFilters.setKeyType(0);
            props.globalFilters.setStay(0);
            props.globalFilters.setRoom(0);
        }
    }

    const goToDevice = (deviceId: number | undefined) => {
        if (deviceId) {
            props.setPrimaryView('device');
            props.globalFilters.setDevice(deviceId);
            props.globalFilters.setRoom(0);
        }
    }

    const goToUser = (userId: number | undefined) => {
        if (userId) {
            props.setPrimaryView('user');
            props.globalFilters.setUser(userId);
        }
    }

    return <div>
        {listOfSavedKeys.length > 0 ?
            <ModalKeyQR key={JSON.stringify(listOfSavedKeys)}
                        qrCode={listOfSavedKeys[0].qr}
                        keyObject={listOfSavedKeys[0].key}
                        remainingModals={listOfSavedKeys.length - 1}
                        defaultEmail={listOfSavedKeys[0].user?.email}
                        onClose={() => setListOfSavedKeys(prevState => prevState.slice(1))}/> : null}
        <div className="header">
            <EntityImage entityType="stay" entity={stay} shape="landscape73"
                         customImage={rooms.find(r => r.idroom === stay.room_idroom)?.image_filename}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="date_start">{t('time.from')}</label>
            <input className="form-control" id="date_start" value={Utils.timestampToInputDateTime(stay?.date_start)}
                   type="datetime-local"
                   onChange={(event) => {
                       setStay({...stay, date_start: Utils.inputDateToTimestamp(event.target.value)})
                   }}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="date_end">{t('time.to')}</label>
            <input className="form-control" id="date_end" value={Utils.timestampToInputDateTime(stay?.date_end)}
                   type="datetime-local"
                   onChange={(event) => {
                       setStay({...stay, date_end: Utils.inputDateToTimestamp(event.target.value)})
                   }}/>
        </div>
        <div className="mb-3">
            <label className="form-label">{t('stay.guestsAndAssignedKeys')}</label>
            <div className="assignedKeys">
                {stay.info_guests?.map(guest =>
                    [
                        <div>
                            <div title={guest.email} onClick={() => goToUser(guest.iduser)} className={'clickable'}>
                                {guest.email}
                                {
                                    (!guest.info_keys || guest.info_keys.length === 0) ?
                                        <span className={'text-muted'}> ({t('stay.noKeys').toLowerCase()})</span>
                                        : null
                                }
                            </div>
                            <div>
                                {guest.info_keys ?
                                    <button className="btn btn-sm btn-danger"
                                            title={t('stay.deleteAllKeysOfGuest')}
                                            onClick={() => deleteKeys(guest.info_keys?.map(key => {
                                                return {key: key, guest: guest};
                                            })!)}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </button> :
                                    (
                                        guest.iduser ? <button className="btn btn-sm btn-danger"
                                                               title={t('stay.deleteGuest')}
                                                               onClick={() => deleteGuest(guest.iduser!)}>
                                            <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                        </button> : null
                                    )
                                }
                            </div>
                        </div>,
                        guest.info_keys?.map(key =>
                            <div className={'stayKey'}>
                                <div>
                                    <span className={'stayTypeIcon clickable'} onClick={() => goToKey(key.idkey)}
                                          title={t('key.type') + ': ' + getKeyTypeI18N(t, key)}>{getKeyType(key)?.icon}
                                    </span>
                                    <span onClick={() => goToKey(key.idkey)} className={'clickable'}
                                          title={getKeyName(key)}> {getKeyName(key)}</span>
                                    <span onClick={() => goToDevice(key.device_iddevice)} className={'clickable'}
                                          title={t('entity.device') + ': ' + key.device_nmk_description}> ({key.device_nmk_description})</span>
                                </div>
                                <div>
                                    <button className="btn btn-sm btn-danger"
                                            title={t('actions.delete')}
                                            onClick={() => deleteKeys([{key: key, guest: guest}])}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </button>
                                </div>
                            </div>
                        )
                    ]
                )}
            </div>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="associatedRoom">{t('stay.associatedRoom')}</label>
            <div className="input-group">
                <select className="form-select" id="associatedRoom" value={stay?.room_idroom}
                        disabled={getCreatedKeys(stay.room_idroom).length > 0}
                        onChange={(event) => setStay({
                            ...stay,
                            room_idroom: parseInt(event.target.value)
                        })}>
                    <option value="0">{t('none')}</option>
                    {
                        rooms.map(room =>
                            <option value={room.idroom}>{room.name}</option>
                        )
                    }
                </select>
                {
                    getCreatedKeys(stay.room_idroom).length > 0 ?
                        <button className={'btn btn-primary'} onClick={() => deleteKeysOfRoom(stay?.room_idroom)}>
                            {t('actions.change')} {t('entity.room').toLowerCase()}
                        </button> : null
                }
            </div>
        </div>
        <div className={'mb-3 ' + (devicesWithKey.length ? '' : 'd-none')}>
            <label className="form-label">
                {t('stay.keysToCreate')}
            </label>
            <div className="deviceList">
                {devicesWithKey.map((compound: DeviceWithKey) =>
                    <div>
                        <div key={compound.device.iddevice} className={'devicesWithKeyGrid'}>
                            <div title={compound.device.nmk_description}>{compound.device.nmk_description}</div>
                            {KeyTypes.map(type => {
                                return <button
                                    className={'text-start text-nowrap btn ' + (compound.keyType === type.id ? 'btn-primary' : 'btn-light')}
                                    title={getKeyTypeI18N(t, type)}
                                    disabled={!compound.device.features?.map(f => getKeyTypeFromFeature(f)?.id).includes(type.id)}
                                    onClick={() => updateDevicesWithKey(compound.device.iddevice, type.id)}>
                                    {type.icon}
                                </button>
                            })}
                        </div>
                        {compound.keyType ?
                        <div className={'miniForm'}>
                            <label className="form-label form-label-sm"
                                htmlFor={'keyName_' + compound.device.iddevice}>{t('key.keyName')}</label>
                            <StringInput value={compound.keyName? compound.keyName: t('entity.key') + ' ' + t('of').toLowerCase() + ' ' + compound.device.nmk_description ?? ''} 
                                id={'keyName_' + compound.device.iddevice}
                                className={'form-control-sm'}
                                disabled={false}
                                onChange={(event) => updateDevicesWithKey_KeyName(compound.device.iddevice, event.target.value)}/>
                            </div> : null
                        }
                        {compound.keyType === KEYTYPE.NFC ?
                            <div className={'miniForm'}>
                                <label className="form-label form-label-sm"
                                       htmlFor={'nfcKeyCode' + compound.device.iddevice}>{t('key.nfcKey')}</label>
                                <NfcInput value={compound.nfc ?? ''} id={'nfcKeyCode' + compound.device.iddevice}
                                          className={'form-control-sm'}
                                          disabled={false}
                                          onChange={(event) => updateDevicesWithKey(compound.device.iddevice, compound.keyType, event.target.value)}/>
                            </div> : null
                        }
                        {compound.keyType === KEYTYPE.PADKEY ?
                            <div className={'miniForm'}>
                                <label className="form-label form-label-sm"
                                       htmlFor={'padKeyCode' + compound.device.iddevice}>{t('key.padKey')}</label>
                                <IntegerInput value={compound.padKey ?? ''} id={'padKeyCode' + compound.device.iddevice}
                                          className={'form-control-sm'}
                                          disabled={false}
                                          onChange={(event) => updateDevicesWithKey_PadKey(compound.device.iddevice, event.target.value)}/>
                            </div> : null
                        }
                    </div>)}
            </div>
        </div>
        <div
            className={'form-check mb-3 ' + (devicesWithKey.find(dk => dk.keyType === KEYTYPE.REMOTE || dk.keyType === KEYTYPE.QR) ? '' : 'd-none')}>
            <input type="checkbox" className="form-check-input" id="hasGeolocationLimit"
                   checked={isGeolocationChecked}
                   onChange={(event) => setGeolocationChecked(event.target.checked)}/>
            <label className="form-check-label" htmlFor="hasGeolocationLimit">{t('key.hasGeolocationLimit')}</label>
        </div>
        {
            isGeolocationChecked && geolocation !== undefined && devicesWithKey.find(dk => dk.keyType === KEYTYPE.REMOTE || dk.keyType === KEYTYPE.QR) ?
                <GeolocationMap geolocation={geolocation} onChange={(geo) => setGeolocation(geo)} readonly={false}/>
                : null
        }
        <div className="mb-3 input-group">
            <label className="form-label" htmlFor="guestUserEmail">{t('stay.guestsEmails')}</label>
            <div className="input-group has-validation">
                <input className={'form-control ' + (showEmailError(userGuestEmail) ? 'is-invalid' : '')}
                       list="guestUserDatalist" id="guestUserEmail" value={userGuestEmail ?? ''}
                       onChange={(event) => setUserGuestEmail(event.target.value)}
                       placeholder={t('stay.guestsEmailPlaceholderTip')}/>
                <datalist id="guestUserDatalist">
                    {
                        knowUsers.map(user =>
                            <option value={user.email}/>
                        )
                    }
                </datalist>
                <button className="btn btn-primary"
                        title={t('actions.add') + ' ' + t('stay.guest').toLowerCase()}
                        onClick={() => {
                            setUserGuestEmailList(prevState => {
                                if (userGuestEmail && userGuestEmail.trim().length > 0)
                                    return Array.from(new Set([userGuestEmail.trim(), ...prevState])); // Remove duplicates too}
                                else
                                    return [...prevState];
                            });
                            setUserGuestEmail('');
                            const target = document.getElementById('guestUserEmail') as HTMLInputElement;
                            if (target) {
                                target.focus();
                            }
                        }}>
                    <FontAwesomeIcon icon={['fas', 'plus']}/>
                </button>
                <div className="invalid-feedback">
                    {t('errors.badEmail')}
                </div>
            </div>
            <div className={'guestList mb-3' + (userGuestEmailList.length ? '' : 'd-none')}>
                {userGuestEmailList.map(email =>
                    <div key={email}>
                        <div title={email}>{email}</div>
                        <div>
                            <button className="btn btn-sm btn-primary"
                                    title={t('actions.delete') + ' ' + t('stay.guest').toLowerCase()}
                                    onClick={() => setUserGuestEmailList(prevState => prevState.filter(e => e !== email))}>
                                <FontAwesomeIcon icon={['fas', 'minus']}/>
                            </button>
                        </div>
                    </div>)}
            </div>
            <div className={'form-check mb-3'}>
                <input type="checkbox" className="form-check-input" id="sendAllKeysByEmail"
                       checked={sendByEmail}
                       onChange={(event) => setSendByEmail(!sendByEmail)}/>
                <label className="form-check-label"
                       htmlFor="sendAllKeysByEmail">{t('stay.sendAllCreatedKeysByEmail')}</label>
            </div>
        </div>
        <div className="d-flex justify-content-evenly">
            {isSaving ? <LoadingSpinner/> : [
                <button className="btn btn-primary" onClick={() => save()}>
                    <FontAwesomeIcon icon={['fas', 'save']} size="lg"/>
                    <span>{t('actions.save')}</span>
                </button>,
                <button className="btn btn-secondary" onClick={() => props.onCancel()}>{t('actions.close')}</button>]}
        </div>
    </div>;
}

