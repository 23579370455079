import React, {useState} from 'react';

import i18n from "i18next";
//import {useTranslation, initReactI18next} from "react-i18next";
import {initReactI18next} from "react-i18next";

import {fab} from '@fortawesome/free-brands-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {library} from '@fortawesome/fontawesome-svg-core';

import {es} from '../internationalizations/es';
import {en} from '../internationalizations/en';

import {MainPage} from "../types/MainPage";
import Panel from "./Panel";
import Login from "./auth/Login";
import SignIn from "./auth/SignIn";
import ResetPassword from "./auth/ResetPassword"
import RevalidateMail from "./auth/RevalidateMail"
import {BackgroundParticles} from "./auth/BackgroundParticles";

import "./Global.scss";
import "./BootstrapOverride.scss";

library.add(fab, fas, far);

// Set spanish language by default if not defined
localStorage.setItem('language', localStorage.getItem('language') ?? 'es');

// Load language
i18n
    .use(initReactI18next)
    .init({
        resources: {
            es: {
                translation: es
            },
            en: {
                translation: en
            } // Add more translation files here <==
        },
        lng: localStorage.getItem('language')!,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false
        }
    });


export default function App() {
    //const {t} = useTranslation();

    const [mainPage, setMainPage] = useState<MainPage>('login');
    const [email, setEmail] = useState<string>();

    return (
        <div>
            <BackgroundParticles/>

            <div style={{position: 'relative'}}>
                {mainPage === 'login' ?
                    <Login
                        onSuccessfulLogin={() => setMainPage('panel')}
                        onSignInIntent={() => setMainPage('signIn')}
                        onResetPassword={() => setMainPage('resetPassword')}
                        onRevalidateMail={(targetEmail: string) => {setEmail(targetEmail); setMainPage('revalidateMail');}}/> : null}
                {mainPage === 'resetPassword' ?
                    <ResetPassword
                        onBackToLogin={() => setMainPage('login')}/> : null}
                {mainPage === 'revalidateMail' ?
                    <RevalidateMail
                        targetEmail= {email}
                        onBackToLogin={() => setMainPage('login')}/> : null}
                {mainPage === 'signIn' ?
                    <SignIn
                        onBackToLogin={() => setMainPage('login')}/> : null}
                {mainPage === 'panel' ?
                    <Panel/> : null}
            </div>
        </div>
    );
}
