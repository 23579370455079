import React from 'react';
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityType} from "../../types/EntityType";
import "./NewEntity.scss";

export default function NewEntity(props: {
    onClick: Function,
    display: 'table' | 'card',
    entity: EntityType
}) {
    const {t} = useTranslation();

    const getContent = () => {
        return [
            <FontAwesomeIcon icon={['fas', 'plus']}/>,
            <div>{props.display === 'table' ?
                t('actions.new') :
                (t('actions.create') + ' ' + t('entity.' + props.entity).toLowerCase())}
            </div>
        ];
    }

    if (props.display === 'card') {
        return <div className={'card newEntity'} onClick={() => props.onClick()}>
            {getContent()}
        </div>
    } else {
        return <th className={'newEntity'} onClick={() => props.onClick()}>
            <button className={'btn btn-primary btn-sm'}
                    title={props.display === 'table' ? (t('actions.create') + ' ' + t('entity.' + props.entity).toLowerCase()) : ''}>
                {getContent()}
            </button>
        </th>;
    }
}

