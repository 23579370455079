import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import Utils from "../../Utils";
import "./LogModal.scss";
import {getLogExtras} from "../../types/LogExtras";
import {Log} from "../../api/generated/Entities";
import {ExtendedEntitiesFactory, ExtendedLog} from "../../api/ExtendedEntities";
import {getKeyName} from "../../types/KeyName";
import {getKeyTypeForLog, getKeyTypeI18N} from "../../types/KeyType";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from "./Modal";
import {CONFIG_FILE} from "../../types/ConfigFile";
import LoadingSpinner from "./LoadingSpinner";


export default function LogModal(props: {
    deviceId: number | undefined,
    keyId: number | undefined,
    onClose: Function,
    title: string
}) {
    const {t} = useTranslation();
    const [logs, setLogs] = useState<Array<Log>>([]);
    const [loadingProgress, setLoadingProgress] = useState<0 | 50 | 100>(0);
    const [geolocation, setGeolocation] = useState<{ lat: number, lon: number } | undefined>(undefined);

    const [timeRange, setTimeRange] = useState<'24h' | '7d' | 'custom' | 'all'>('24h');
    const [customFrom, setCustomFrom] = useState<number | undefined>(undefined)
    const [customTo, setCustomTo] = useState<number | undefined>(undefined)

    useEffect(() => {
        setLoadingProgress(0);
        let filter: any = {};
        if (!props.deviceId && !props.keyId) {
            return;
        } else if (props.deviceId) {
            filter = {iddevice: props.deviceId}
        } else if (props.keyId) {
            filter = {idkey: props.keyId}
        }

        let {from, to} = getDateRange();
        if (timeRange !== 'all') {
            if (!from || !to) {
                setLogs([]);
                setLoadingProgress(100);
                return;
            }
            filter.fromtimestamp = from;
            filter.totimestamp = to;
        }
        filter.includeInfo = true;
        Api.getLogs(filter).then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                if (!value.content || !Array.isArray(value.content) || value.content.length === 0) {
                    setLogs([]);
                    setLoadingProgress(100);
                    return;
                }
                value.content = value.content.reverse();
                setLogs(value.content);
                setLoadingProgress(100);
                // ExtendedEntitiesFactory.getExtendedLogs(value.content).then(extendedLogs => {
                //     setLoadingProgress(100);
                //     setLogs(extendedLogs);
                // });
            }
        });
    }, [timeRange, customFrom, customTo]);

    const getDateRange = (): { from: number | undefined, to: number | undefined } => {
        switch (timeRange) {
            case "24h":
                return {
                    from: Utils.getTimestampNow(-1),
                    to: Utils.getTimestampNow(),
                }
            case "7d":
                return {
                    from: Utils.getTimestampNow(-7),
                    to: Utils.getTimestampNow(),
                }
            case "custom":
                return {
                    from: customFrom,
                    to: customTo ? (customTo + (60 * 60 * 24)) : undefined,
                }
            case "all":
                return {
                    from: undefined,
                    to: undefined,
                }
        }
    }

    return (props.deviceId || props.keyId) ?
        <Modal visible={true} onClose={props.onClose} title={props.title}>
            <div className={'logContainer'}>
                <div className={'logDateFilters ' + (timeRange !== 'custom' ? 'hiddenInputs' : '')}>
                    <div className={'btn-group'}>
                        <button className={'btn ' + (timeRange === '24h' ? 'btn-primary' : 'btn-light')}
                                onClick={() => setTimeRange('24h')}>
                            {t('time.last24hours')}
                        </button>
                        <button className={'btn ' + (timeRange === '7d' ? 'btn-primary' : 'btn-light')}
                                onClick={() => setTimeRange('7d')}>
                            {t('time.lastWeek')}
                        </button>
                        <button className={'btn ' + (timeRange === 'all' ? 'btn-primary' : 'btn-light')}
                                onClick={() => setTimeRange('all')}>
                            {t('all')}
                        </button>
                        <button className={'btn ' + (timeRange === 'custom' ? 'btn-primary' : 'btn-light')}
                                onClick={() => setTimeRange('custom')}>
                            {t('time.custom')}{timeRange === 'custom' ? ':' : ''}
                        </button>
                    </div>
                    <div className={'input-group'}>
                        <input className="form-control" type="date" value={Utils.timestampToInputDate(customFrom)}
                                onChange={(event) => {
                                    setCustomFrom(Utils.inputDateToTimestamp(event.target.value))
                                }}/>
                        <input className="form-control" type="date" value={Utils.timestampToInputDate(customTo)}
                                onChange={(event) => {
                                    setCustomTo(Utils.inputDateToTimestamp(event.target.value))
                                }}/>
                    </div>
                    {loadingProgress !== 100 ? <LoadingSpinner style={{
                        position: 'absolute',
                        right: 0,
                        transform: 'translate(0, -20px) scale(0.5)',
                        opacity: loadingProgress === 50 ? 0.5 : 1
                    }}/> : null}
                </div>
                <div className={'logTableContainer'}>
                    <table className="table table-hover align-middle logTable">
                        <thead>
                        <tr>
                            <th>{t('time.date')}</th>
                            {props.deviceId ? <th>{t('entity.key')}</th> : null}
                            {props.deviceId ? <th>{t('log.accessType')}</th> : null}
                            {props.keyId ? <th>{t('entity.device')}</th> : null}
                            <th>{t('log.message')}</th>
                            <th>{t('geolocation.geolocation')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {logs?.map(log =>
                            <tr>
                                <td>{Utils.dateTimeFormatter(log.nmk_epoch_timestamp)}</td>

                                {/*Device log modal*/}
                                {props.deviceId ?
                                    [
                                        <td title={log.idlog?.toString()} >{log.info_key_name ?? ''}<br/>{log.info_assignedto_email ?? log.info_creator_email ?? ''}</td>,
                                        <td title={getKeyTypeForLog(log)?.id?.toString()}>
                                            {log ? (getKeyTypeForLog(log)?.icon) : (getLogExtras(log, t)?.keyTypeIcon)}
                                            {' '}
                                            {log ? (getKeyTypeI18N(t, getKeyTypeForLog(log))) : (getLogExtras(log, t)?.keyTypeName)}
                                        </td>
                                    ] : null}

                                {/*Key log modal*/}
                                {props.keyId ?
                                    [
                                        <td title={log.idlog?.toString()} >{log.info_device_name ?? (t('unnamed') + "ID: " + log.device_iddevice ?? 0)}</td>
                                    ] : null}

                                <td title={(log.nmk_granted ? t('log.granted') : log.nmk_text)}>
                                    {getLogExtras(log, t)?.eventTypeName ?? (log.nmk_granted ? t('log.granted') : t('log.noGranted'))}</td>
                                <td>
                                    {getLogExtras(log, t)?.geolocation ?
                                        <button className={'btn btn-primary btn-sm'}
                                                onClick={() => setGeolocation(getLogExtras(log, t)?.geolocation)}>
                                            <FontAwesomeIcon icon={['fas', 'map-marked-alt']}/>
                                            <span>{t('actions.open')} {t('geolocation.map').toLowerCase()}</span>
                                        </button> : null}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
            {geolocation ? <Modal visible={!!geolocation} title={t('geolocation.geolocation')}
                                    onClose={() => setGeolocation(undefined)}>
                <iframe
                    width="600"
                    height="600"
                    style={{border: 0, display: 'block'}}
                    loading="lazy"
                    title="geoLoc"
                    src={'https://www.google.com/maps/embed/v1/place?key=' + CONFIG_FILE.googleMapApiKey + '&q=' + geolocation.lat + ',' + geolocation.lon + ''}>
                </iframe>
            </Modal> : null}
        </Modal> : null;
}

