import {Complex, Device, Key, Room, Stay, User} from "./generated/Entities";
import {KEYTYPE, getKeyType} from "../types/KeyType";

import {CONFIG_FILE} from "../types/ConfigFile";
import {ExtendedKey} from "./ExtendedEntities"
import {NomorePass} from "./NomorePass";
import {getKeyName} from "../types/KeyName";

export default class Api {
    static token: string;
    static _intervals: NodeJS.Timeout[] = [];

    static isError(response: any, mustBe?: 'mustBeArray' | 'mustBeArrayWithObject' | 'mustBeObject', showAlert: boolean = true): boolean {
        // Server errors without any response, only browser auto responses
        if ((typeof response.status !== 'object' && response.status !== 'ok' && response.status !== 'ko')
            /*|| En teoria no debe venir un error asi del navegador
            (typeof response.status === 'object' && response.status.status !== 'ok' && response.status.status !== 'ko')*/) {
            if (showAlert) {
                if (response.title) {
                    alert(response.title + (response.detail ? ('. ' + response.detail) : ('')) + ' (' + response.status + ')');
                } else {
                    alert(response.status);
                }
            }
            return true;
        }
        // Errors can came in 2 types:
        //          {error: ['text'], status: 'ko'}
        // {status: {error: ['text'], status: 'ko'}}
        if (response.status === 'ko') {
            if (response.error[0] === 'Bad token') {
                window.location.reload();
            } else if (showAlert) {
                alert(response.error[0] ?? 'Unknown error');
            }
            return true;
        } else if (response.status?.status === 'ko') {
            if (response.status.error[0] === 'Bad token') {
                window.location.reload();
            } else if (showAlert) {
                alert(response.status.error[0] ?? 'Unknown error');
            }
            return true;
        }
        // Test inner content
        if (mustBe) {
            if (!response.content) {
                if (showAlert) {
                    alert('Empty content');
                }
                return true;
            } else if (mustBe === 'mustBeArray') {
                if (!Array.isArray(response.content)) {
                    if (showAlert) {
                        alert('Null array');
                    }
                    return true;
                }
            } else if (mustBe === 'mustBeArrayWithObject') {
                if (!Array.isArray(response.content)) {
                    if (showAlert) {
                        alert('Null array');
                    }
                    return true;
                }
                if (!response.content[0]) {
                    if (showAlert) {
                        alert('No data');
                    }
                    return true;
                }
            }
        }
        return false;
    }

    static getToken(body: { email: string, password: string }) {
        return fetch(CONFIG_FILE.basePath + '/auth/getToken', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static refreshToken(refreshToken: string) {
        return fetch(CONFIG_FILE.basePath + '/auth/refreshToken?' + new URLSearchParams({token: refreshToken}), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static requestNewPass(email: string) {
        return fetch(CONFIG_FILE.basePath + '/user/requestNewPass', {
            method: 'POST',
            body: JSON.stringify({email: email}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static resendValidationMail(email: string) {
        return fetch(CONFIG_FILE.basePath + '/user/resendValidationMail', {
            method: 'POST',
            body: JSON.stringify({email: email}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    /**
     * @param user User object
     * @param parentToken Only if there is a user creating a new user
     */
    static registerLocalUser(user: User, parentToken?: string) {
        let params: any = {source: user};
        if (parentToken) {
            params.token = parentToken;
        }
        return fetch(CONFIG_FILE.basePath + '/user/registerLocalUser', {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static searchDevice(filter?: any) {
        return fetch(CONFIG_FILE.basePath + '/device/searchDevice', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, includeFeatures: true, ...filter}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static getFeatures() {
        return fetch(CONFIG_FILE.basePath + '/device/getFeatures', {
            method: 'POST',
            body: JSON.stringify({token: Api.token}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static searchKey(filter?: any) {
        return fetch(CONFIG_FILE.basePath + '/key/searchKey', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, ...filter}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static searchUser(filter?: any) {
        return fetch(CONFIG_FILE.basePath + '/user/searchUser', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, ...filter}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static getMe() {
        return Api.searchUser({id: 0});
    }

    static updateUser(user: User) {
        return fetch(CONFIG_FILE.basePath + '/user/updateUser', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, source: user}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static getKnowUsers() {
        return fetch(CONFIG_FILE.basePath + '/user/getKnownUsers', {
            method: 'POST',
            body: JSON.stringify({token: Api.token}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static searchRoom(filter?: any) {
        return fetch(CONFIG_FILE.basePath + '/room/searchRoom', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, ...filter}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static searchComplex(filter?: any) {
        return fetch(CONFIG_FILE.basePath + '/complex/searchComplex', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, ...filter}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static searchStay(filter?: any) {
        return fetch(CONFIG_FILE.basePath + '/stay/searchStay', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, includeInfo: true, ...filter}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static getLogs(filter?: any) {
        return fetch(CONFIG_FILE.basePath + '/log/getLogs', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, ...filter}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static setKey(key?: Key) {
        return fetch(CONFIG_FILE.basePath + '/key/setKey', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, source: key}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static sendKeyByEmail(keyId: number, targetEmail: string, credentialString: string) {
        return fetch(CONFIG_FILE.basePath + '/key/sendKeyByEmail', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, idkey: keyId, targetEmail: targetEmail, credentialString: credentialString}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static deleteKeys(keyList: number[]) {
        return Api.deleteEntityList(keyList, '/key/delKey');
    }

    static deleteDevices(deviceList: number[]) {
        return Api.deleteEntityList(deviceList, '/device/delDevice');
    }

    static deleteStays(stayList: number[]) {
        return Api.deleteEntityList(stayList, '/stay/delStay');
    }

    static deleteRooms(roomList: number[]) {
        return Api.deleteEntityList(roomList, '/room/delRoom');
    }

    static deleteComplexes(complexList: number[]) {
        return Api.deleteEntityList(complexList, '/complex/delComplex');
    }

    private static deleteEntityList(list: number[], path: string) {
        let promiseList: Promise<any>[] = list.map(id => {
            return fetch(CONFIG_FILE.basePath + path, {
                method: 'POST',
                body: JSON.stringify({token: Api.token, id: id}),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(r => r.json());
        });
        return new Promise(resolve => {
            Promise.all(promiseList).then(value => {
                resolve(value);
            })
        })
    }

    static setDevice(device?: Device) {
        return fetch(CONFIG_FILE.basePath + '/device/setDevice', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, source: device}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static setRoom(room?: Room) {
        return fetch(CONFIG_FILE.basePath + '/room/setRoom', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, source: room}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static setComplex(complex?: Complex) {
        return fetch(CONFIG_FILE.basePath + '/complex/setComplex', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, source: complex}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static setStay(stay?: Stay) {
        return fetch(CONFIG_FILE.basePath + '/stay/setStay', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, source: stay}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static pairGuestToStay(stayId: number, guestId: number) {
        return fetch(CONFIG_FILE.basePath + '/stay/pairGuest', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, idstay: stayId, idguest: guestId}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static unpairGuestFromStay(stayId: number, guestId: number) {
        return fetch(CONFIG_FILE.basePath + '/stay/unpairGuest', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, idstay: stayId, idguest: guestId}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static pairGuestToKey(keyId: number, guestId: number) {
        return fetch(CONFIG_FILE.basePath + '/user/pairKey', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, iduser: guestId, idkey: keyId}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static unpairGuestFromKey(keyId: number, guestId: number) {
        return fetch(CONFIG_FILE.basePath + '/user/unpairKey', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, iduser: guestId, idkey: keyId}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static pairStayToKey(stayId: number, keyId: number) {
        return fetch(CONFIG_FILE.basePath + '/stay/pairKey', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, idstay: stayId, idkey: keyId}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static unpairStayFromKey(stayId: number, keyId: number) {
        return fetch(CONFIG_FILE.basePath + '/stay/unpairKey', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, idstay: stayId, idkey: keyId}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static sendGuestsCredentials(stayId: number, credentials: { idguest: number, credentialString: string }[]) {
        return fetch(CONFIG_FILE.basePath + '/stay/sendGuestsCredentials', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, idstay: stayId, credentials: credentials}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static getFirmwareModels() {
        return fetch(CONFIG_FILE.basePath + '/device/getModels', {
            method: 'POST',
            body: JSON.stringify({token: Api.token}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static updateFirmwareState(iddevice: number) {
        return fetch(CONFIG_FILE.basePath + '/device/updateDeviceState', {
            method: 'POST',
            body: JSON.stringify({token: Api.token, iddevice: iddevice, state: 'update'}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static getDevicesStatusValues() {
        return fetch(CONFIG_FILE.basePath + '/device/getStatusValues', {
            method: 'POST',
            body: JSON.stringify({token: Api.token}),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(r => r.json());
    }

    static uploadImage(imageType: 'USER' | 'COMPLEX' | 'ROOM' | 'DEVICE', file: Blob, entityId?: number) {
        let formData = new FormData();
        formData.append('token', Api.token);
        formData.append('img_type', imageType);
        formData.append('fileName', file);
        if (entityId) {
            formData.append('target_id', entityId + '');
        }
        return new Promise<any>(resolve => {
            fetch(CONFIG_FILE.basePath + '/user/uploadImage', {
                method: 'POST',
                body: formData
            }).then(r => r.json()).then(value => {
                setTimeout(() => {
                    resolve(value);
                }, 1000); // 1 second delay to wait backend save the image
            });
        });
    }

    /**
     * Tests if QR code is used, resolving the returned promise when resolved.
     * To stop testing, use Api.pingQRClearIntervals() function to stop spamming nomorepass service.
     * @param ticket
     */
    static pingQR(ticket: string): Promise<void> {
        return new Promise<void>(resolve => {
            let waiting = false;
            const interval = setInterval(() => {
                if (!waiting) {
                    waiting = true;
                    let formData = new FormData();
                    formData.append('device', 'WEBDEVICE');
                    formData.append('ticket', ticket);
                    fetch('https://nomorepass.com/api/ping.php', {
                        method: 'POST',
                        body: formData
                    }).then(r => r.json()).then(value => {
                        if (value.ping === "ko") {
                            clearInterval(interval);
                            resolve();
                        }
                        waiting = false;
                    });
                }
            }, 1000);
            Api._intervals.push(interval);
        });
    }

    /**
     * Clean pingQR possible created intervals. Use it to cancel testing to nomorepass server
     */
    static pingQRClearIntervals() {
        Api._intervals.map(interval => clearInterval(interval));
        Api._intervals = [];
    }

    static getNomorepassKeyQR(createdKey: ExtendedKey, generatedKeyPassword: string) {
        return new Promise<string | undefined>(resolve => {
            let typeString: string = KEYTYPE[getKeyType(createdKey)?.id ?? 0];
            if (typeString !== 'BLEKEY' &&
                typeString !== 'SOUNDKEY' &&
                typeString !== 'LIGHTKEY' &&
                typeString !== 'PADKEY' ) {
                alert('Key type not supported: ' + typeString + '. Only BLEKEY, SOUNDKEY, LIGHTKEY AND PADKEY supported');
                resolve(undefined);
                return;
            }
            if (!createdKey.idkey) {
                alert('No idkey found for key');
                resolve(undefined);
                return;
            }
            let extra: any = {external_id: createdKey.idkey, type: typeString};
            if (typeString === 'SOUNDKEY') {
                extra.secret = '1234567890123456';
            }
            if (typeString === 'PADKEY'){
                typeString = 'KEY';
                extra.type = 'padkey';
            }
            NomorePass.init({'apikey': 'FREEKEY'});
            NomorePass.expiry = Math.trunc(Date.now() / 1000 + 172800); // 172800 seconds - 2days

            console.log("Sending key to nomorepasw with host: "+ createdKey.ex_device?.nmk_idstr);
            NomorePass.getQrNomoreKeys(getKeyName(createdKey), 'key', generatedKeyPassword, typeString, {'host':createdKey.ex_device?.nmk_idstr,'extra': extra}, (text: any) => {
                console.log('Getted from nomorepass: ' + text);
                resolve(text);
            });
        });
    }

    static getNomorepassUserPasswordQR(createdKey: Key, userToken: string, passwordToken: string) {
        return new Promise<string | undefined>(resolve => {
            if (getKeyType(createdKey)?.id !== KEYTYPE.QR) {
                alert('Key type not supported. Only QR is supported for this function');
                resolve(undefined);
                return;
            }
            if (!createdKey.idkey) {
                alert('No idkey found for user-password key');
                resolve(undefined);
                return;
            }
            let extra: any = {type: 'pwd', external_id: createdKey.idkey};
            if (createdKey.nmk_geo) {
                try {
                    extra.geo = JSON.parse(createdKey.nmk_geo);
                } catch (e) {
                    console.log('Error parsing nmk_geo: ', createdKey.nmk_geo, e);
                    alert('Error parsing nmk_geo');
                }
            }
            NomorePass.init({'apikey': 'FREEKEY'});
            NomorePass.expiry = Math.trunc(Date.now() / 1000 + 172800); // 172800 seconds - 2 days
            NomorePass.getQrSend(getKeyName(createdKey), userToken, passwordToken, {'extra': extra}, (text: any) => {
                console.log('Getted from nomorepass: ' + text);
                resolve(text);
            });
        });
    }
}

// Only for debugging purposes:
// @ts-ignore
window['Api'] = Api;
