import {Feature, Key, Log} from "../api/generated/Entities";
import {ExtendedKey} from  "../api/ExtendedEntities";
import {TFunction} from "i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export enum KEYTYPE {
    INDETERMINATE = 0,
    SOUNDKEY = 1,
    LIGHTKEY = 2,
    NFC = 3,
    QR = 4,
    REMOTE = 5,
    MANUAL = 6, // User manually open a door
    BLEKEY = 7,
    PADKEY = 8
}

export type KeyType = {
    id: KEYTYPE,
    userOrKey: 'user' | 'key', // Same as Key.user_or_key in src/api/generated/Entities.ts
    keyTypeId: number | undefined,
    userIsRemoteKey: boolean | undefined,
    icon: JSX.Element,
    featureId: number
}

const isKeyType = (keyType: any): keyType is KeyType => {
    return keyType !== undefined &&
        keyType !== null &&
        typeof keyType === 'object' &&
        typeof keyType.id === 'number' &&
        keyType.userOrKey !== undefined;
}

export const KeyTypes: KeyType[] = [
    {
        id: KEYTYPE.NFC,
        userOrKey: 'key',
        keyTypeId: 0,
        userIsRemoteKey: undefined,
        icon: <FontAwesomeIcon fixedWidth={true} icon={['fas', 'id-card']}/>,
        featureId: 1
    },
    {
        id: KEYTYPE.BLEKEY,
        userOrKey: 'key',
        keyTypeId: 1,
        userIsRemoteKey: undefined,
        icon: <FontAwesomeIcon fixedWidth={true} icon={['fab', 'bluetooth']}/>,
        featureId: 2
    },
    {
        id: KEYTYPE.SOUNDKEY,
        userOrKey: 'key',
        keyTypeId: 3,
        userIsRemoteKey: undefined,
        icon: <FontAwesomeIcon fixedWidth={true} icon={['fas', 'volume-up']}/>,
        featureId: 3
    },
    {
        id: KEYTYPE.LIGHTKEY,
        userOrKey: 'key',
        keyTypeId: 4,
        userIsRemoteKey: undefined,
        icon: <FontAwesomeIcon fixedWidth={true} icon={['fas', 'lightbulb']}/>,
        featureId: 4
    },
    {
        id: KEYTYPE.QR,
        userOrKey: 'user',
        keyTypeId: undefined,
        userIsRemoteKey: false,
        icon: <FontAwesomeIcon fixedWidth={true} icon={['fas', 'qrcode']}/>,
        featureId: 6
    },
    {
        id: KEYTYPE.REMOTE,
        userOrKey: 'user',
        keyTypeId: undefined,
        userIsRemoteKey: true,
        icon: <FontAwesomeIcon fixedWidth={true} icon={['fas', 'globe-europe']}/>,
        featureId: 5
    },
    {
        id: KEYTYPE.PADKEY,
        userOrKey: 'key',
        keyTypeId: 5,
        userIsRemoteKey: undefined,
        icon: <FontAwesomeIcon fixedWidth={true} icon={['fas', 'th']}/>,
        featureId: 7
    }

]

/**
 * Extract a frontend friendly key type KeyType
 * @param key
 */
export const getKeyType = (key: Key): KeyType | undefined => {
    if (!key) {
        return;
    }
    if (key.user_or_key === 'key') {
        if (key.nmk_key_type === undefined) {
            return;
        }
        return KeyTypes.find(type => type.keyTypeId === key.nmk_key_type);
    } else if (key.user_or_key === 'user') {
        if (key.user_remote === undefined) {
            return;
        }
        return KeyTypes.find(type => type.userIsRemoteKey === key.user_remote);
    }
}
/**
 * Extract a frontend friendly key type KeyType
 * @param key
 */
export const getKeyTypeForLog = (log: Log): KeyType | undefined => {
    if (!log) {
        return;
    }
    if (log.info_key_user_or_key === 'key') {
        if (log.info_key_nmk_key_type === undefined) {
            return;
        }
        return KeyTypes.find(type => type.keyTypeId === log.info_key_nmk_key_type);
    } else if (log.info_key_user_or_key === 'user') {
        if (log.info_key_user_remote === undefined) {
            return;
        }
        return KeyTypes.find(type => type.userIsRemoteKey === log.info_key_user_remote);
    }
}

export const getKeyTypeFromFeature = (feature: Feature): KeyType | undefined => {
    if (!feature) {
        return;
    }
    return KeyTypes.find(type => type.featureId === feature.idfeature as Feature);
}

export const getKeyTypeI18N = (t: TFunction, key: KEYTYPE | KeyType | Key | undefined) => {
    // Error handling
    if (!key && key !== 0) {
        return '';
    }
    // KEYTYPE enum
    if (typeof key === 'number') {
        return t('key.types.' + key);
    }
    // KeyType type
    if (isKeyType(key)) {
        return t('key.types.' + key.id);
    }
    // Key type
    let keyType = getKeyType(key);
    if (!keyType) {
        return '';
    }
    return t('key.types.' + keyType.id);
}

export const getKeyTypeDescriptionI18N = (t: TFunction, key: KEYTYPE | KeyType | Key | undefined) => {
    // Error handling
    if (!key && key !== 0) {
        return '';
    }
    // KEYTYPE enum
    if (typeof key === 'number') {
        return t('key.typesDescription.' + key);
    }
    // KeyType type
    if (isKeyType(key)) {
        return t('key.typesDescription.' + key.id);
    }
    // Key type
    let keyType = getKeyType(key);
    if (!keyType) {
        return '';
    }
    return t('key.typesDescription.' + keyType.id);
}

export const setKeyType = (key: Key, typeEnum: KEYTYPE): Key => {
    const type: KeyType | undefined = KeyTypes.find(kt => kt.id === typeEnum);
    if (!type) {
        return key;
    }
    key.user_or_key = type.userOrKey;
    key.nmk_key_type = type.keyTypeId;
    key.user_remote = type.userIsRemoteKey;
    return key;
}

export const clearKeyType = (key: Key): Key => {
    key.user_or_key = undefined;
    key.nmk_key_type = undefined;
    key.user_remote = undefined;
    return key;
}

export const clearExtendedKeyType = (key: ExtendedKey): ExtendedKey => {
    key.user_or_key = undefined;
    key.nmk_key_type = undefined;
    key.user_remote = undefined;
    return key;
}

export const getUrlNomorepassGetShared = (qrcode: string): string => {
    // Format doc: https://redmine.biblioeteca.com/projects/nomorekeys-cerradura-inteligente/wiki/Tipos_de_llaves
    qrcode = qrcode.replace('nomorepass://SENDPASS', ''); // Remote and QR keys (user keys)
    qrcode = qrcode.replace('nomorekeys://SENDPASS', ''); // Remote and QR keys (user keys)
    qrcode = qrcode.replace('nomorekeys://', '');         // Sound, Light and BLE key (key type)

    let base_url = window.location.origin;

    //return 'https://nomorepass.com/getshared/' + encodeURIComponent(encodeURIComponent(qrcode));
    return base_url + '/getshared.html?param=' + encodeURIComponent(encodeURIComponent(qrcode));
}
