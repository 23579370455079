import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import logo from "../../assets/logoNmk_blue.png";
import Api from "../../api/Api";
import "./Auth.scss";


export default function SignIn(props: { onBackToLogin: Function }) {
    const {t} = useTranslation();

    const [email, setEmail] = useState<string>('');
    const [password1, setPassword1] = useState<string>('');
    const [password2, setPassword2] = useState<string>('');

    const validateEmail = (email: string): boolean => {
        const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
    }

    const showEmailError = (email: string): boolean => email !== '' && !validateEmail(email);

    const showPasswordsError = (password1: string, password2: string): boolean => password1 !== '' && password2 !== '' && password1 !== password2;

    const signIn = () => {
        if (showEmailError(email) ||
            showPasswordsError(password1, password2) ||
            email === '' ||
            password1 === '' ||
            password2 === '') {
            alert(t('errors.pleaseFillAllFields'));
            return;
        }
        Api.registerLocalUser({
            email: email,
            password: password1
        }).then(value => {
            if (value.status.status === 'ko') {
                alert(value.status.error[0]);
            } else {
                props.onBackToLogin();
                alert(t('user.successfulSignin'));
            }
        })
    }

    return (
        <div className="cardContainer auth">
            <div className="card">
                <div className="card-body">
                    <img src={logo} alt=""/>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="emailRecover">{t('user.email')}</label>
                        <input className={'form-control ' + (showEmailError(email) ? 'is-invalid' : '')}
                               id="emailRecover" autoComplete="off"
                               onChange={(event) => setEmail(event.target.value)}
                        />
                        <div className="invalid-feedback">
                            {t('errors.badEmail')}
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="password1Recover">{t('user.password')}</label>
                        <input className="form-control" type="password" id="password1Recover" autoComplete="off"
                               onChange={(event) => setPassword1(event.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <label className="form-label" htmlFor="password2Recover">{t('actions.passwordRepeat')}</label>
                        <input
                            className={'form-control ' + (showPasswordsError(password1, password2) ? 'is-invalid' : '')}
                            type="password" id="password2Recover" autoComplete="off"
                            onChange={(event) => setPassword2(event.target.value)}
                        />
                        <div className="invalid-feedback">
                            {t('errors.badRepeatedPassword')}
                        </div>
                    </div>
                    {/* Old sign in "as GUEST". Now it is only "sign in"
                    <button className="btn btn-primary"
                            onClick={() => signIn()} style={{display: 'block', margin: '1rem auto 0.5rem auto'}}>
                        {t('actions.signInAsGuest')}
                    </button>*/}
                    <button className="btn btn-primary"
                            onClick={() => signIn()} style={{display: 'block', margin: '1rem auto 0.5rem auto'}}>
                        {t('actions.signIn')}
                    </button>
                </div>
                <div className="card-footer">
                    <div className="mb-1 tip">
                        {t('questions.alreadyAccount')}
                    </div>
                    <button className="btn btn-light"
                            onClick={() => props.onBackToLogin()}>
                        {t('actions.login_order')}...
                    </button>
                </div>
            </div>
        </div>
    );
}
