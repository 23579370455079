import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import Utils from "../../Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityType} from "../../types/EntityType";
import {SecondaryView} from "../../types/SecondaryView";
import {ExtendedEntitiesFactory, ExtendedStay} from "../../api/ExtendedEntities";
import {Stay} from "../../api/generated/Entities";
import EntityImage from "../misc/EntityImage";
import ConfirmDeleteBox from "../misc/ConfirmDeleteBox";
import ConditionalLink from "../misc/ConditionalLink";
import {GlobalFilters} from "../Panel";
import {ViewMode} from "../../types/PrimaryView";
import NewEntity from "../misc/NewEntity";


export default function Stays(props: {
    update: number, // Increment to reload the list of entities
    setPrimaryView: (pv: EntityType) => void,
    setSecondaryView: (sv: SecondaryView) => void,
    globalFilters: GlobalFilters,
    viewMode: ViewMode
}) {
    const {t} = useTranslation();
    const [stays, setStays] = useState<Array<ExtendedStay>>([]);
    const [selectedToDelete, setSelectedToDelete] = useState<number[]>([]);

    const firstRun = useRef(true); // Prevent executing useEffect[props.update] on first render

    useEffect(() => {
        getAPIFilteredStays();
    }, []);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return
        }
        getAPIFilteredStays();
    }, [props.update]);

    const getStays = () => {
        Api.searchStay().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setStays(value.content);
                ExtendedEntitiesFactory.getExtendedStays(value.content).then(extendedStays => {
                    setStays(extendedStays);
                });
            }
        });
    }

    const getFilteredStays = () => {
        return stays
            // .filter((stays: Stay) => !props.globalFilters.stay || stays.idstay === props.globalFilters.stay)
            // .filter((stays: Stay) => !props.globalFilters.room || stays.room_idroom === props.globalFilters.room)
            ;
    }

    const getAPIFilteredStays = () => {
        // Prepare filter
        var filter: Record<string, any> = {}
        if (props.globalFilters.stayState){
            filter.state = props.globalFilters.stayState;
        }
        if (props.globalFilters.stay){
            filter.id = props.globalFilters.stay;
        }
        if (props.globalFilters.room){
            filter.idroom = props.globalFilters.room;
        }
        if (props.globalFilters.stayLocatorCode){
            filter.locator_code = props.globalFilters.stayLocatorCode;
        }
        if (props.globalFilters.stayDateFrom && !(props.globalFilters.stayDateFrom === '')){
            filter.startdate = Utils.inputDateToTimestamp(props.globalFilters.stayDateFrom);
        }
        if (props.globalFilters.stayDateTo&& !(props.globalFilters.stayDateTo === '')){
            filter.enddate = Utils.inputDateToEndDayTimestamp(props.globalFilters.stayDateTo);
        }
        Api.searchStay(filter).then(value=>{
            if (!Api.isError(value, 'mustBeArray')){
                setStays(value.content);
                ExtendedEntitiesFactory.getExtendedStays(value.content).then(extendedStays => {
                    setStays(extendedStays);
                });
            }
        })
    }


    const goToRooms = (roomId: number | undefined) => {
        if (roomId) {
            props.setPrimaryView('room');
            props.globalFilters.setRoom(roomId);
            props.globalFilters.setComplex(0);
        }
    }

    const goToKeys = (stayId: number | undefined) => {
        if (stayId) {
            props.setPrimaryView('key');
            props.globalFilters.setKey(0);
            props.globalFilters.setDevice(0);
            props.globalFilters.setStay(stayId);
            props.globalFilters.setRoom(0);
        }
    }

    const toggleDelete = (stayId: number | undefined) => {
        if (!stayId) {
            return;
        }
        if (selectedToDelete.includes(stayId)) {
            setSelectedToDelete(selectedToDelete.filter(i => i !== stayId));
        } else {
            setSelectedToDelete([...selectedToDelete, stayId]);
        }
    };

    const deleteStays = () => {
        Api.deleteStays(selectedToDelete).then((values: any) => {
            for (let value of values) {
                Api.isError(value);
            }
            setSelectedToDelete([]);
            getAPIFilteredStays();
        })
    };

    const getEntitiesContainer = () => {
        if (props.viewMode === 'listMode') {
            return <div className={'tableContainer'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        <th>{t('id')}</th>
                        <th>{t('entity.room')}</th>
                        <th>{t('entity.key', {context: 'plural'})}</th>
                        <th>{t('time.start')}</th>
                        <th>{t('time.end')}</th>
                        <NewEntity entity={'stay'} onClick={() => props.setSecondaryView({entity: 'stay', id: 0})}
                                   display={'table'}/>
                    </tr>
                    </thead>
                    <tbody>
                    {getFilteredStays().map(stay => [
                        <tr className={selectedToDelete.includes(stay.idstay ?? 0) ? 'rowSelectedToDelete' : ''}>
                            <td title={""+stay.idstay}>{stay.locator_code}</td>
                            <td>
                                <ConditionalLink content={stay.ex_room?.name ?? stay.room_idroom ?? t('none')}
                                                 onClick={stay.room_idroom ? () => goToRooms(stay.room_idroom) : null}
                                                 appearance={'link'}/>
                            </td>
                            <td>
                                <ConditionalLink content={stay.ex_keys?.length}
                                                 onClick={stay.ex_keys?.length ? () => goToKeys(stay.idstay) : null}
                                                 appearance={'link'}/>
                            </td>
                            <td>{Utils.dateTimeFormatter(stay.date_start)}</td>
                            <td>{Utils.dateTimeFormatter(stay.date_end)}</td>
                            <td>
                                <div className="btn-group btn-group-sm">
                                    <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                            onClick={() => props.setSecondaryView({entity: 'stay', id: stay.idstay})}>
                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                        <span>{t('actions.edit')}</span>
                                    </button>
                                    <button className="btn btn-sm btn-danger" title={t('actions.delete')}
                                            onClick={() => toggleDelete(stay.idstay)}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ])}
                    </tbody>
                </table>
            </div>;
        } else {
            return <div className="cardsContainer">
                <NewEntity entity={'stay'} onClick={() => props.setSecondaryView({entity: 'stay', id: 0})}
                           display={'card'}/>
                {
                    getFilteredStays().map(stay => <div
                        className={'card ' + (selectedToDelete.includes(stay.idstay ?? 0) ? 'cardSelectedToDelete' : '')}
                        key={stay.idstay}>
                        <div className="card-header">
                            <EntityImage entityType="stay" entity={stay} shape="landscape73"
                                         customImage={stay.ex_room?.image_filename}/>
                        </div>
                        <div className="card-body">
                            <table className="entityDataTable">
                                <tbody>
                                <tr>
                                    <td>{t('id')}</td>
                                    <td title={""+stay.idstay}>{stay.locator_code}</td>
                                </tr>
                                <tr>
                                    <td>{t('entity.room')}</td>
                                    <td>
                                        <ConditionalLink content={stay.ex_room?.name ?? stay.room_idroom ?? t('none')}
                                                         onClick={stay.room_idroom ? () => goToRooms(stay.room_idroom) : null}
                                                         appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('entity.key', {context: 'plural'})}</td>
                                    <td>
                                        <ConditionalLink content={stay.ex_keys?.length}
                                                         onClick={stay.ex_keys?.length ? () => goToKeys(stay.idstay) : null}
                                                         appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('time.start')}</td>
                                    <td>{Utils.dateTimeFormatter(stay.date_start)}</td>
                                </tr>
                                <tr>
                                    <td>{t('time.end')}</td>
                                    <td>{Utils.dateTimeFormatter(stay.date_end)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer">
                            <div className="btn-group btn-group-sm">
                                <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                        onClick={() => props.setSecondaryView({entity: 'stay', id: stay.idstay})}>
                                    <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    <span>{t('actions.edit')}</span>
                                </button>
                                <button className="btn btn-sm btn-danger" title={t('actions.delete')}
                                        onClick={() => toggleDelete(stay.idstay)}>
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                </button>
                            </div>
                        </div>
                    </div>)}
            </div>;
        }
    }

    return <div className={'primaryViewContainer'}>
        <ConfirmDeleteBox entityType={'stay'} listOfSelectedEntities={selectedToDelete}
                          onCancel={() => setSelectedToDelete([])} onClickDeleteButton={() => deleteStays()}/>
        <div className={'entitiesContainer'}>{getEntitiesContainer()}</div>
    </div>


}

