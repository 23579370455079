import {EntityType, isEntityType} from "./EntityType";

/**
 * Defines which section is selected and which table is displayed
 */
export type PrimaryView = EntityType;

export const isPrimaryView = isEntityType;

export type ViewMode = 'gridMode' | 'listMode';
