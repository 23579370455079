import {TFunction} from "i18next";
import {Log} from "../api/generated/Entities";
import {getKeyTypeI18N, KEYTYPE, KeyTypes} from "./KeyType";

export type LogExtras = {
    keyType?: KEYTYPE,
    keyTypeName?: string,
    keyTypeIcon?: JSX.Element,
    eventType?: LOGEXTRAS_EVENTTYPE,
    eventTypeName?: string,
    geolocation?: { lat: number, lon: number }
}

// Enum keys isn't used for nothing yet. Only numbers are important
export enum LOGEXTRAS_EVENTTYPE {
    UNKNOW = 0,
    LOCK = 1, // AVISO DE BLOQUEO
    UNLOCK = 2, // AVISO DE DESBLOQUEO
    LOCK_FAILURE_INVALID_PIN_OR_ID = 3, // AVISO DE BLOQUEO ERRONEO
    LOCK_FAILURE_INVALID_SCHEDULE = 4,  // AVISO DE BLOQUEO ERRONEO (HORARIO ERRONEO)
    UNLOCK_FAILURE_INVALID_PIN_OR_ID = 5, // AVISO DE DESBLOQUEO ERRONEO
    UNLOCK_FAILURE_INVALID_SCHEDULE = 6, // AVISO DE DESBLOQUEO ERRONEO (HORARIO ERRONEO)
    ONE_TOUCH_LOCK = 7, // UNUSED
    KEY_LOCK = 8, // UNUSED
    KEY_UNLOCK = 9, // UNUSED
    AUTO_LOCK = 10, // UNUSED
    SCHEDULE_LOCK = 11,
    SCHEDULE_UNLOCK = 12,
    MANUAL_LOCK = 13,  // AVISO DE BLOQUEO CON LLAVE
    MANUAL_UNLOCK = 14,  // AVISO DE DESBLOQUEO CON LLAVE
    QR_REQUEST = 15, // MANDAR PETICION DE GENERAR QR
    LOCK_FAILURE_INVALID_CREDENTIAL = 25, // AVISO DE CREDENCIALES ERRONEAS AL BLOQUEAR
    UNLOCK_FAILURE_INVALID_CREDENTIAL = 26, // AVISO DE CREDENCIALES ERRONEAS AL DESBLOQUEAR
    CODE_QR_ABORT = 29, // MANDAR PETICION DE ABORTAR GENERACION DE QR
}

export const getLogExtras = (log: Log, t: TFunction): LogExtras | undefined => {
    if (!log || !log.nmk_extra) {
        return;
    }
    let backendExtras;
    try {
        backendExtras = JSON.parse(log.nmk_extra);
    } catch (error) {
        return;
    }
    if (!backendExtras) {
        return;
    }
    let logExtras: LogExtras = {};
    if (backendExtras.acces_type) { // Workaround misspelling TODO REMOVE IN FUTURE
        backendExtras.access_type = backendExtras.acces_type;
        delete backendExtras.acces_type;
    }
    if (backendExtras.access_type) {
        if (typeof backendExtras.access_type === 'string') {
            backendExtras.access_type = parseInt(backendExtras.access_type);
        }
        logExtras.keyType = backendExtras.access_type;
        logExtras.keyTypeIcon = KeyTypes.find(type => type.id === logExtras.keyType)?.icon;
        logExtras.keyTypeName = getKeyTypeI18N(t, backendExtras.access_type);
    }
    if (backendExtras.event_type) {
        if (typeof backendExtras.event_type === 'string') {
            backendExtras.event_type = parseInt(backendExtras.event_type);
        }
        logExtras.eventType = backendExtras.event_type;
        logExtras.eventTypeName = getEventLogI18N(t, backendExtras.event_type);
    }
    if (backendExtras.lat && backendExtras.lon) {
        if (typeof backendExtras.lat === 'string') {
            backendExtras.lat = parseFloat(backendExtras.lat);
        }
        if (typeof backendExtras.lon === 'string') {
            backendExtras.lon = parseFloat(backendExtras.lon);
        }
        if (typeof backendExtras.lat === 'number' && !isNaN(backendExtras.lat) &&
            typeof backendExtras.lon === 'number' && !isNaN(backendExtras.lon)) {
            logExtras.geolocation = {lat: backendExtras.lat, lon: backendExtras.lon}
        }
    }
    return logExtras;
}

export const getEventLogI18N = (t: TFunction, eventLog: LOGEXTRAS_EVENTTYPE) => {
    return t('log.events.' + eventLog);
}
