import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
//AMC: warning removal ->import Utils from "../../Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityType} from "../../types/EntityType";
import {SecondaryView} from "../../types/SecondaryView";
import {ExtendedEntitiesFactory, ExtendedRoom} from "../../api/ExtendedEntities";
import {Room} from "../../api/generated/Entities";
import EntityImage from "../misc/EntityImage";
import ConfirmDeleteBox from "../misc/ConfirmDeleteBox";
import ConditionalLink from "../misc/ConditionalLink";
import {GlobalFilters} from "../Panel";
import {ViewMode} from "../../types/PrimaryView";
import NewEntity from "../misc/NewEntity";


export default function Rooms(props: {
    update: number, // Increment to reload the list of entities
    setPrimaryView: (pv: EntityType) => void,
    setSecondaryView: (sv: SecondaryView) => void,
    globalFilters: GlobalFilters,
    viewMode: ViewMode
}) {
    const {t} = useTranslation();
    const [rooms, setRooms] = useState<Array<ExtendedRoom>>([]);
    const [selectedToDelete, setSelectedToDelete] = useState<number[]>([]);

    const firstRun = useRef(true); // Prevent executing useEffect[props.update] on first render

    useEffect(() => {
        getRooms();
    }, []);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return
        }
        getRooms();
    }, [props.update]);

    const getRooms = () => {
        Api.searchRoom().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setRooms(value.content);
                ExtendedEntitiesFactory.getExtendedRooms(value.content).then(extendedRooms => {
                    setRooms(extendedRooms);
                });
            }
        });
    }

    const getFilteredRooms = () => {
        return rooms
            .filter((room: Room) => !props.globalFilters.room || room.idroom === props.globalFilters.room)
            .filter((room: Room) => !props.globalFilters.complex || room.complex_idcomplex === props.globalFilters.complex)
    }

    const goToComplexes = (complexId: number | undefined) => {
        if (complexId) {
            props.setPrimaryView('complex');
            props.globalFilters.setComplex(complexId);
        }
    }

    const goToDevices = (roomId: number | undefined) => {
        if (roomId) {
            props.setPrimaryView('device');
            props.globalFilters.setDevice(0);
            props.globalFilters.setRoom(roomId);
        }
    }

    const goToStays = (roomId: number | undefined) => {
        if (roomId) {
            props.setPrimaryView('stay');
            props.globalFilters.setStay(0);
            props.globalFilters.setRoom(roomId);
        }
    }

    const goToKeys = (roomId: number | undefined) => {
        if (roomId) {
            props.setPrimaryView('key');
            props.globalFilters.setKey(0);
            props.globalFilters.setDevice(0);
            props.globalFilters.setStay(0);
            props.globalFilters.setRoom(roomId);
        }
    }

    const toggleDelete = (roomId: number | undefined) => {
        if (!roomId) {
            return;
        }
        if (selectedToDelete.includes(roomId)) {
            setSelectedToDelete(selectedToDelete.filter(i => i !== roomId));
        } else {
            setSelectedToDelete([...selectedToDelete, roomId]);
        }
    };

    const deleteRooms = () => {
        Api.deleteRooms(selectedToDelete).then((values: any) => {
            for (let value of values) {
                Api.isError(value);
            }
            setSelectedToDelete([]);
            getRooms();
        })
    };

    const getEntitiesContainer = () => {
        if (props.viewMode === 'listMode') {
            return <div className={'tableContainer'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        <th>{t('id')}</th>
                        <th>{t('name')}</th>
                        <td>{t('entity.complex')}</td>
                        <td>{t('entity.device', {context: 'plural'})}</td>
                        <td>{t('entity.stay', {context: 'plural'})}</td>
                        <td>{t('entity.key', {context: 'plural'})}</td>
                        <NewEntity entity={'room'} onClick={() => props.setSecondaryView({entity: 'room', id: 0})}
                                   display={'table'}/>
                    </tr>
                    </thead>
                    <tbody>
                    {getFilteredRooms()
                        .map(room => <tr
                            className={selectedToDelete.includes(room.idroom ?? 0) ? 'rowSelectedToDelete' : ''}>
                            <td>{room.idroom}</td>
                            <td>{room.name}</td>
                            <td>
                                <ConditionalLink content={room.ex_complex?.name ?? room.complex_idcomplex ?? t('none')}
                                                 onClick={room.complex_idcomplex ? () => goToComplexes(room.complex_idcomplex) : null}
                                                 appearance={'badge'}/>
                            </td>
                            <td>
                                <ConditionalLink content={room.ex_devices?.length}
                                                 onClick={room.ex_devices?.length ? () => goToDevices(room.idroom) : null}
                                                 appearance={'badge'}/>
                            </td>
                            <td>
                                <ConditionalLink content={room.ex_stays?.length}
                                                 onClick={room.ex_stays?.length ? () => goToStays(room.idroom) : null}
                                                 appearance={'badge'}/>
                            </td>
                            <td>
                                <ConditionalLink content={room.ex_keys?.length}
                                                 onClick={room.ex_keys?.length ? () => goToKeys(room.idroom) : null}
                                                 appearance={'badge'}/>
                            </td>
                            <td>
                                <div className="btn-group btn-group-sm">
                                    <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                            onClick={() => props.setSecondaryView({entity: 'room', id: room.idroom})}>
                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    </button>
                                    <button className="btn btn-sm btn-info"
                                            title={t('actions.create') + ' ' + t('entity.stay').toLowerCase()}
                                            onClick={() => props.setSecondaryView({
                                                entity: 'stay',
                                                id: undefined,
                                                extras: {roomId: room.idroom}
                                            })}>
                                        <FontAwesomeIcon icon={['fas', 'plus']}/>
                                        <span>{t('actions.create') + ' ' + t('entity.stay').toLowerCase()}</span>
                                    </button>
                                    <button className="btn btn-sm btn-danger" title={t('actions.delete')}
                                            onClick={() => toggleDelete(room.idroom)}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </button>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>;
        } else {
            return <div className="cardsContainer">
                <NewEntity entity={'room'} onClick={() => props.setSecondaryView({entity: 'room', id: 0})}
                           display={'card'}/>
                {getFilteredRooms()
                    .map(room => <div
                        className={'card ' + (selectedToDelete.includes(room.idroom ?? 0) ? 'cardSelectedToDelete' : '')}
                        key={room.idroom}>
                        <div className="card-header">
                            <EntityImage entityType="room" entity={room} shape="landscape73" customImage={room.image_filename}/>
                        </div>
                        <div className="card-body">
                            <div className="cardTitle">
                                {room.name}
                            </div>
                            <table className="entityDataTable">
                                <tbody>
                                <tr>
                                    <td>{t('id')}</td>
                                    <td>{room.idroom}</td>
                                </tr>
                                <tr>
                                    <td>{t('entity.complex')}</td>
                                    <td>
                                        <ConditionalLink content={room.ex_complex?.name ?? room.complex_idcomplex ?? t('none')}
                                                         onClick={room.complex_idcomplex ? () => goToComplexes(room.complex_idcomplex) : null}
                                                         appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('entity.device', {context: 'plural'})}</td>
                                    <td>
                                        <ConditionalLink content={room.ex_devices?.length}
                                                         onClick={room.ex_devices?.length ? () => goToDevices(room.idroom) : null}
                                                         appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('entity.stay', {context: 'plural'})}</td>
                                    <td>
                                        <ConditionalLink content={room.ex_stays?.length}
                                                         onClick={room.ex_stays?.length ? () => goToStays(room.idroom) : null}
                                                         appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('entity.key', {context: 'plural'})}</td>
                                    <td>
                                        <ConditionalLink content={room.ex_keys?.length}
                                                         onClick={room.ex_keys?.length ? () => goToKeys(room.idroom) : null}
                                                         appearance={'badge'}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer">
                            <div className="btn-group btn-group-sm">
                                <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                        onClick={() => props.setSecondaryView({entity: 'room', id: room.idroom})}>
                                    <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                </button>
                                <button className="btn btn-sm btn-info"
                                        title={t('actions.create') + ' ' + t('entity.stay').toLowerCase()}
                                        onClick={() => props.setSecondaryView({
                                            entity: 'stay',
                                            id: undefined,
                                            extras: {roomId: room.idroom}
                                        })}>
                                    <FontAwesomeIcon icon={['fas', 'plus']}/>
                                    <span>{t('actions.create') + ' ' + t('entity.stay').toLowerCase()}</span>
                                </button>
                                <button className="btn btn-sm btn-danger" title={t('actions.delete')}
                                        onClick={() => toggleDelete(room.idroom)}>
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                </button>
                            </div>
                        </div>
                    </div>)}
            </div>;
        }
    }

    return <div className="primaryViewContainer">
        <ConfirmDeleteBox entityType={'room'} listOfSelectedEntities={selectedToDelete}
                          onCancel={() => setSelectedToDelete([])} onClickDeleteButton={() => deleteRooms()}/>
        <div className={'entitiesContainer'}>{getEntitiesContainer()}</div>
    </div>;
}

