import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {Complex, Room} from "../../api/generated/Entities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//AMC: warning removal ->import Utils from "../../Utils";
//AMC: warning removal ->import roomImage from "../../assets/entities/portal2_room.png";
import EntityImage from "../misc/EntityImage";


export default function RoomEdit(props: {
    id: number,
    onSave: () => void,
    onCancel: () => void
}) {
    const {t} = useTranslation();
    const [room, setRoom] = useState<Room>({});
    const [uploadedImage, setUploadedImage] = useState<Blob | undefined>(undefined);
    const [complexes, setComplexes] = useState<Complex[]>([]);

    useEffect(() => {
        if (props.id > 0) {
            Api.searchRoom({id: props.id}).then(value => {
                if (!Api.isError(value, 'mustBeArrayWithObject')) {
                    setRoom(value.content[0]);
                }
            });
        }
        Api.searchComplex().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setComplexes(value.content);
            }
        });
    }, []);

    const save = async () => {
        let saveResponse = await Api.setRoom(room);
        if (uploadedImage) {
            let uploadResponse = await Api.uploadImage('ROOM', uploadedImage, saveResponse.content.idroom);
            Api.isError(uploadResponse);
        }
        if (!Api.isError(saveResponse)) {
            props.onSave();
        }
    }

    return <div>
        <div className="header">
            <EntityImage entityType="room" entity={room} shape="landscape73" customImage={room.image_filename}
                         onUploadNewImage={(imageFile) => setUploadedImage(imageFile)}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="roomName">{t('name')}</label>
            <input className="form-control" id="roomName" value={room?.name}
                   onChange={(event) => setRoom({...room, name: event.target.value})}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="associatedComplex">{t('room.associatedComplex')}</label>
            <select className="form-select" id="associatedComplex" value={room?.complex_idcomplex}
                    onChange={(event) => setRoom({
                        ...room,
                        complex_idcomplex: parseInt(event.target.value)
                    })}>
                <option value="0">{t('none')}</option>
                {
                    complexes.map(complex =>
                        <option value={complex.idcomplex}>{complex.name}</option>
                    )
                }
            </select>
        </div>
        <div className="d-flex justify-content-evenly">
            <button className="btn btn-primary" onClick={() => save()}>
                <FontAwesomeIcon icon={['fas', 'save']} size="lg"/>
                <span>{t('actions.save')}</span>
            </button>
            <button className="btn btn-secondary" onClick={() => props.onCancel()}>{t('actions.close')}</button>
        </div>
    </div>;
}

