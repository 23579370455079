import sha from "crypto-js/sha1";

export default class Utils {
    private static _cacheCanvas: HTMLCanvasElement

    /**
     * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
     *
     * @param {String} text The text to be rendered.
     * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
     *
     * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
     *
     * @example
     * Utils.getTextWidth("hello there!", "bold 12pt arial"));  // close to 86
     */
    static getTextWidth(text: string, font: string): number {
        // Re-use canvas object for better performance
        let canvas = Utils._cacheCanvas || (Utils._cacheCanvas = document.createElement("canvas"));
        let context = canvas.getContext("2d");
        if (context) {
            context.font = font;
            let metrics = context.measureText(text);
            return metrics.width;
        }
        return 0;
    }

    /**
     * Return computed width in pixels of given text
     * @param {String} title.
     *
     * @example
     * Utils.getColumnWidth("User name"));
     */
    static getColumnWidth(title: string): number {
        let columnWidth = Utils.getTextWidth(title, 'normal 14px roboto') + 95;
        //console.log('Text: "' + title + '" measures ' + columnWidth + 'px');
        return columnWidth;
    }

    /**
     * Adds property "id" to all items of given array. "id" property will be the same value as object["identifier"] parameter
     * @example
     * addUniqueId([{id_user: 5, name: 'Ismael'}], 'id_user')
     * [{id_user: 5, name: 'Ismael', id: 5}] // <- returned. id_user and id have the same value: 5
     * @param array
     * @param identifier
     */
    static addUniqueId<T>(array: T[], identifier: string): Array<T & { id: number }> {
        return array.map((item: T) => {
            // @ts-ignore
            item.id = item[identifier];
            return item as (T & { id: number; });
        });
    }

    /**
     * It converts api UNIX UTC number to region formatted user-friendly datetime.
     * @param value
     */
    static dateTimeFormatter = (value: number | string | undefined | null) => {
        if (typeof value === 'string') {
            value = parseInt(value);
        }
        return typeof value === 'number' ? new Date(value * 1000).toLocaleString() : ''
    }

    static expiredTimestamp = (value: number | undefined | null) => {
        if (value === undefined || value === null){
            return false;
        }else
            return value < Math.floor(new Date().getTime()/1000);
    }
    /**
     * Returns procedural number between 0 and 360 based from an object as hash seed
     * @param thing
     */
    static getHashRadius = (thing: string | number | object | undefined) => {
        if (!thing) {
            return 0;
        }
        if (typeof thing === 'object') {
            thing = JSON.stringify(thing);
        }
        if (typeof thing === 'number') {
            thing = String(thing);
        }
        // Get 4 first letters from hashed object
        const hash = sha(thing).toString().substring(0, 4);
        //console.log(hash);
        // Parse hexadecimal to base 10 integer:
        const integer = parseInt('0x' + hash);
        //console.log(integer % 360);
        // Get one turn
        return integer % 360;
    }

    /**
     * Converts timestamp from server to formatted text to <input type="datetime-local">
     * @example
     * <input className="form-control" type="datetime-local"
     * value={Utils.timestampToInputDateTime(device?.nmk_expires)} // <-- HERE
     * onChange={(event) => {
     *                 setDevice({...device, nmk_expires: Utils.inputDateToTimestamp(event.target.value)})
     *          }}/>
     * @param value
     */
    static timestampToInputDateTime = (value: number | string | undefined): string => {
        if (!value) {
            return '';
        }
        if (typeof value === 'string') {
            value = parseInt(value);
        }
        value *= 1000;
        // https://stackoverflow.com/questions/43329252/how-to-convert-javascript-date-object-to-string-that-is-compatible-with-datetime
        const offset = new Date().getTimezoneOffset() * 1000 * 60;
        const offsetDate = new Date(value).valueOf() - offset;
        const date = new Date(offsetDate).toISOString();
        return date.substring(0, 16);
    }


    /**
     * Converts timestamp from server to formatted text to <input type="datetime-local">
     * @example
     * <input className="form-control" type="datetime-local"
     * value={Utils.timestampToInputDateTime(device?.nmk_expires)} // <-- HERE
     * onChange={(event) => {
     *                 setDevice({...device, nmk_expires: Utils.inputDateToTimestamp(event.target.value)})
     *          }}/>
     * @param value
     */
    static timestampToInputDate = (value: number | string | undefined): string => {
        return Utils.timestampToInputDateTime(value).substring(0, 10);
    }

    /**
     * Converts values from <input type="datetime-local"> or  <input type="date"> to timestamp
     * @example
     * <input className="form-control" type="datetime-local"
     * value={Utils.timestampToInputDateTime(device?.nmk_expires)}
     * onChange={(event) => {
     *                 setDevice({...device, nmk_expires: Utils.inputDateToTimestamp(event.target.value)}) // <-- HERE
     *          }}/>
     * @param value
     */
    static inputDateToTimestamp = (value: string | undefined): number => {
        if (!value)
            return 0;
        else
            return Math.round(new Date(value).getTime() / 1000);
    }
    static inputDateToEndDayTimestamp = (value: string | undefined): number => {
        if (!value)
            return 0;
        else{
            var actualDate = new Date(value);
            actualDate.setHours(23,59,59,999);
            return Math.round(actualDate.getTime() / 1000);
        }
            
    }

    static getTimestampNow = (daysOffset: number = 0): number => {
        let now = Math.round(new Date().valueOf() / 1000);
        now = now + (60 * 60 * 24 * daysOffset);
        return now;
    }

    /**
     * Generate random alphanumeric token
     * @author Jose Antonio Espinosa
     */
    static generateAlphanumericToken = (length: number) => {
        let charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal.toUpperCase();
    }

    /**
     * https://stackoverflow.com/questions/6108819/javascript-timestamp-to-relative-time
     * @author vsync
     * @param timestamp
     */
    static getRelativeTime = (timestamp: number | undefined) => {
        if (!timestamp) {
            return '';
        }

        // Convert seconds timestamp to milliseconds timestamp
        if (timestamp < 10000000000) { //10.000.000.000
            timestamp *= 1000;
        }

        const units: any = {
            year  : 24 * 60 * 60 * 1000 * 365,
            month : 24 * 60 * 60 * 1000 * 365/12,
            day   : 24 * 60 * 60 * 1000,
            hour  : 60 * 60 * 1000,
            minute: 60 * 1000,
            second: 1000
        }

        let rtf = new Intl.RelativeTimeFormat(localStorage.getItem('language') ?? 'es', { numeric: 'auto' });

        let elapsed = timestamp - new Date().getTime();

        // "Math.abs" accounts for both "past" & "future" scenarios
        for (let u in units) {
            if (Math.abs(elapsed) > units[u] || u === 'second') {
                //@ts-ignore
                return rtf.format(Math.round(elapsed / units[u]), u);
            }
        }
    }
}

// @ts-ignore
window['utils'] = Utils;
