//AMC: warning removal ->import Api from "../api/Api";

type ConfigFile = {
    basePath: string
    signInOwnerWeb: string
    googleMapApiKey: string
};

export let CONFIG_FILE: ConfigFile;

/**
 * Get config object from root/public/config.json
 */
export const getConfigFile = (): Promise<void> => {
    return new Promise<void>((resolve) => {
        fetch('config.json?' + Math.random())
            .then(response => response.json())
            .then((data: ConfigFile) => {
                CONFIG_FILE = data;
                resolve();
            });
    });
}
