export const en = {
    entity: {
        key: 'Key',
        key_plural: 'Keys',
        device: 'Device',
        device_plural: 'Devices',
        room: 'Room',
        room_plural: 'Rooms',
        complex: 'Complex',
        complex_plural: 'Complexes',
        stay: 'Stay',
        stay_plural: 'Stays',
        user: 'User',
        user_plural: 'Users',
        log: 'Log',
        firmware: 'firmware'
    },
    profile: 'Profile',
    myProfile: 'My profile',
    actions: {
        save: 'Save',
        edit: 'Edit',
        delete: 'Remove',
        markToDelete: 'Mark to delete',
        unmark: 'Unmark',
        create: 'Create',
        new: 'New',
        new_female: 'New',
        add: 'Add',
        close: 'Close',
        cancel: 'Cancel',
        share: 'Share',
        yes: 'Yes',
        no: 'No',
        see: 'See',
        upgrade: 'Upgrade',
        change: 'Change',
        logout: 'Logout',
        filter: 'Filter',
        filterDates: 'Filter by dates',
        open: 'Open',
        login: 'Login',
        login_order: 'Login',
        signIn: 'Sign in',
        signIn_order: 'Sign in',
        signInAsGuest: '$t(actions.signIn) as guest',
        signInAsGuest_order: '$t(actions.signIn_order) as guest',
        signInAsOwner: '$t(actions.signIn) as owner',
        signInAsOwner_order: '$t(actions.signIn_order) as owner',
        passwordRepeat: 'Repeat the password',
        resetPass: 'Reset password',
        resetPass_order: 'Reset password',
        uploadNewImage: 'Upload new image',
        resendValidation: 'Send validation email again'
    },
    questions: {
        noAccount: 'Don\'t have an account?',
        alreadyAccount: 'Already have an account?',
        passwordForgotten: 'Forgot your password?'
    },
    nomorepass: {
        tip: 'Never forget your password with',
        discover: 'Discover nomorepass'
    },
    errors: {
        pleaseFillAllFields: 'Please fill in all the fields',
        badEmail: 'Invalid email',
        badRepeatedPassword: 'Passwords do not match',
        badUserOrPassword: 'Incorrect username or password',
        noData: 'No data',
        noId: 'No id'
    },
    time: {
        from: 'From',
        to: 'To',
        start: 'Start',
        end: 'End',
        date: 'Date',
        expire: 'Expire',
        last24hours: 'Last 24h',
        lastWeek: 'Last week',
        custom: 'Custom'
    },
    status: {
        status: 'Status',
        active: 'Active',
        inactive: 'Inactive',
        activated: 'Activated',
        inactivated: 'Inactivated'
    },
    viewMode: {
        grid: 'Grid view',
        list: 'List view'
    },
    id: 'Id',
    name: 'Name',
    noName: 'No name',
    none: 'None',
    none_female: 'None',
    all: 'All',
    all_masculine: 'All',
    all_female: 'All',
    type: 'Type',
    of: 'Of',
    for: 'For',
    in: 'In',
    or: 'Or',
    remaining: 'Remaining',
    remaining_plural: 'Remaining',
    loading: 'Loading',
    selected: 'Selected',
    selected_female: 'Selected',
    selected_plural: 'Selected',
    selected_plural_female: 'Selected',
    youHave: 'You have',
    unnamed: 'No name',
    placeholders:{
        email: 'Type email',
        send: 'Send'
    },
    geolocation: {
        geolocation: 'Geolocation',
        map: 'Map',
        lat: 'Latitude',
        lon: 'Longitude',
        rad: 'Radius',
        showInputs: 'Show extra inputs',
        hideInputs: 'Hide extra inputs'
    },
    user: {
        email: 'Email',
        password: 'Password',
        successfulSignin: 'Successful registration',
        resetEmailSent: 'Email sent',
        resendValidationSent: 'Validation email sent again',
        revalidationTitle: 'Email not validated yet',
        revalidationText1: 'Check your inbox and spam folders for the validation mail we sent you and complete validation process',
        revalidationText2: 'If you cannot find it we can resend the validation mail pressing next button'
    },
    key: {
        type: 'Key type',
        types: { /* From enum KEYTYPE */
            '0': '',
            '1': 'Sound key',
            '2': 'Light key',
            '3': 'NFC',
            '4': 'QR',
            '5': 'Remote',
            '6': 'Manual',
            '7': 'BLE',
            '8': 'Padkey'
        },
        description: 'Description',
        typesDescription: { /* From enum KEYTYPE */
            '0': '',
            '1': 'Keys that use ultrasound to send credentials to the lock.',
            '2': 'Keys that allow credentials to be sent to the lock by pulses of light.',
            '3': 'The system supports nfc tokens, in the form of a card or a keychain in the Mifare classic or Mifare Ultralight format. These types of keys are not stored on the mobile but are physical devices that must be physically delivered to the user. It is useful as an emergency entrance, in case of not having internet or if the user already has these keys integrated for other purposes.',
            '4': 'Keys that use a single-use QR to exchange encrypted keys over the internet.',
            '5': 'Keys that can be used directly from the mobile phone without having to be in front of the lock.',
            '6': '',
            '7': 'Keys that allow credentials to be sent to the lock using Bluetooth Low Energy technology.',
            '8': 'Keys that can be typed in a keypad'
        },
        keyName: 'Key name',
        nfcKey: 'NFC key',
        padKey: 'Keypad key',
        userDescription: 'Title',
        associatedDevice: 'Associated device',
        hasGeolocationLimit: 'Limit by geolocation',
        lastUse: 'Last use',
        timesUsed: 'Times used',
        modalQRTip: 'Scan the following QR code with the mobile device where you want to store the key or send it by email',
        modalButtonAlreadyScanned: 'Already scanned',
        modalButtonAlreadyEmailed: 'Already sent',
        modalButtonSendEmail: 'Send link by email...',
        errors: {
            noKeyType: 'No key type selected',
            nfcBadFormat: 'NFC bad format',
            integerBadFormat: 'Integer bad format',
            keyNotSaved: 'Key not saved'
        }
    },
    device: {
        customer: 'Customer',
        description: 'Description',
        lastPing: 'Last connection',
        associatedRoom: 'Associated room',
        category: 'Category',
        categories: {
            smartLock: 'Smart lock',
            gateway: 'Gateway',
            accessDevice: 'Access device',
            lockGateway: 'Gateway Lock'
        },
        hardwareId: 'Hardware Id',
        featuresAvailable: 'Features available',
        mainStatus: {
            locked: 'Locked',
            unlocked: 'Unlocked',
            armed: 'Armed',
            unarmed: 'Unarmed',
            unknown: 'Unknown'
        },
        savedOkMessage: 'Device successfully created with activation code',
        mustHaveFeature: 'Device must have at least one feature selected',
        updateFirmware: 'Continuing will update the device with the latest available firmware.'

    },
    room: {
        associatedComplex: 'Associated complex',
        noDevicesFoundForRoom: 'The room has no devices'
    },
    stay: {
        associatedRoom: 'Associated room',
        guestsAndAssignedKeys: 'Guest and their assigned keys',
        keysToCreate: 'Keys to create',
        noKeys: 'No keys',
        guest: 'Guest',
        guest_plural: 'Guests',
        guestsEmails: 'Guest\'s emails',
        guestsEmailPlaceholderTip: 'Type an email and press the + button',
        confirmCreateWithoutEmails: 'You have not entered any guest email, do you want to continue anyway?',
        confirmCreateWithoutKeys: 'You will not create any key, do you want to continue anyway?',
        confirmInputEmailNotAdded: 'You have entered an email in the "$t(stay.guestsEmails)" text field but have not added it to the list, do you want to continue anyway?',
        deleteAllKeysOfGuest: 'Delete all keys of this guest',
        deleteGuest: 'Remove guest from stay',
        changeRoomConfirm: 'You are going to delete all keys created for the current room, do you want to continue?',
        sendAllCreatedKeysByEmail: 'Send all keys by email',
        emailsSent: 'Emails sent',
        extraRoom: 'Extra room',
        extraRooms: 'Extra rooms',
        state:{
            all: 'All',
            past: 'Past',
            current: 'Current',
            future: 'Future',
            valid: 'Valid'
        },
        locatorCode: 'Code'
    },
    log: {
        message: 'Message',
        granted: '✔ ️Granted',
        noGranted: '❌ Not granted',
        accessType: 'Access type',
        events: { /* From enum EVENTLOG */
            '0': '❌ Unknow',                            // UNKNOW
            '1': '✔ Closed',                            // LOCK                             // AVISO DE BLOQUEO
            '2': '✔ Opened',                            // UNLOCK                           // AVISO DE DESBLOQUEO
            '3': '❌ Error on close',                    // LOCK_FAILURE_INVALID_PIN_OR_ID   // AVISO DE BLOQUEO ERRONEO
            '4': '❌ Error on close (invalid schedule)', // LOCK_FAILURE_INVALID_SCHEDULE    // AVISO DE BLOQUEO ERRONEO (HORARIO ERRONEO)
            '5': '❌ Error on open',                     // UNLOCK_FAILURE_INVALID_PIN_OR_ID // AVISO DE DESBLOQUEO ERRONEO
            '6': '❌ Error on open (invalid schedule)',  // UNLOCK_FAILURE_INVALID_SCHEDULE  // AVISO DE DESBLOQUEO ERRONEO (HORARIO ERRONEO)
            '7': 'ONE_TOUCH_LOCK',        // UNUSED     // ONE_TOUCH_LOCK
            '8': 'KEY_LOCK',              // UNUSED     // KEY_LOCK
            '9': 'KEY_UNLOCK',            // UNUSED     // KEY_UNLOCK
            '10': 'AUTO_LOCK',            // UNUSED     // AUTO_LOCK
            '11': '✔ Scheduled close',                  // SCHEDULE_LOCK
            '12': '✔ Scheduled open',                   // SCHEDULE_UNLOCK
            '13': '✔ Manual close',                     // MANUAL_LOCK                            // AVISO DE BLOQUEO MANUAL
            '14': '✔ Manual open',                      // MANUAL_UNLOCK                          // AVISO DE DESBLOQUEO MANUAL
            '15': '✔ QR code request',                  // QR_REQUEST                             // MANDAR PETICION DE GENERAR QR
            '25': '❌ Error on close (invalid credentials)', // LOCK_FAILURE_INVALID_CREDENTIAL    // AVISO DE CREDENCIALES ERRONEAS AL BLOQUEAR
            '26': '❌ Error on open (invalid credentials)',  // UNLOCK_FAILURE_INVALID_CREDENTIAL  // AVISO DE CREDENCIALES ERRONEAS AL DESBLOQUEAR
            '29': '❌ QR generation aborted'                 // CODE_QR_ABORT                      // MANDAR PETICION DE ABORTAR GENERACION DE QR
        }
    }
}
