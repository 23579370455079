import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {Complex} from "../../api/generated/Entities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//AMC: warning removal ->import Utils from "../../Utils";
//AMC: warning removal ->import complexImage from "../../assets/entities/drhouse_complex.png";
import EntityImage from "../misc/EntityImage";


export default function ComplexEdit(props: {
    id: number,
    onSave: () => void,
    onCancel: () => void
}) {
    const {t} = useTranslation();
    const [complex, setComplex] = useState<Complex>({});
    const [uploadedImage, setUploadedImage] = useState<Blob | undefined>(undefined);

    useEffect(() => {
        if (props.id > 0) {
            Api.searchComplex({id: props.id}).then(value => {
                if (!Api.isError(value, 'mustBeArrayWithObject')) {
                    setComplex(value.content[0]);
                }
            });
        }
    }, []);

    const save = async () => {
        let saveResponse = await Api.setComplex(complex);
        if (uploadedImage) {
            let uploadResponse = await Api.uploadImage('COMPLEX', uploadedImage, saveResponse.content.idcomplex);
            Api.isError(uploadResponse);
        }
        if (!Api.isError(saveResponse)) {
            props.onSave();
        }
    }

    return <div>
        <div className="header">
            <EntityImage entityType="complex" entity={complex} shape="landscape73" customImage={complex.image_filename}
                         onUploadNewImage={(imageFile) => setUploadedImage(imageFile)}/>
        </div>
        <div className="mb-3">
            <label className="form-label" htmlFor="complexName">{t('name')}</label>
            <input className="form-control" id="complexName" value={complex?.name}
                   onChange={(event) => setComplex({...complex, name: event.target.value})}/>
        </div>
        <div className="d-flex justify-content-evenly">
            <button className="btn btn-primary" onClick={() => save()}>
                <FontAwesomeIcon icon={['fas', 'save']} size="lg"/>
                <span>{t('actions.save')}</span>
            </button>
            <button className="btn btn-secondary" onClick={() => props.onCancel()}>{t('actions.close')}</button>
        </div>
    </div>;
}

