import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Key} from "../../api/generated/Entities";
import Api from "../../api/Api";
import {EntityType} from "../../types/EntityType";
import {SecondaryView} from "../../types/SecondaryView";
import Utils from "../../Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getKeyType, getKeyTypeI18N, KEYTYPE} from "../../types/KeyType";
import {getKeyName} from "../../types/KeyName";
import LogModal from "../misc/LogModal";
import EntityImage from "../misc/EntityImage";
import ConfirmDeleteBox from "../misc/ConfirmDeleteBox";
//AMC: warning removal ->import _ from "lodash";
import {ExtendedEntitiesFactory, ExtendedKey} from "../../api/ExtendedEntities";
import ConditionalLink from "../misc/ConditionalLink";
import {GlobalFilters} from "../Panel";
import {ViewMode} from "../../types/PrimaryView";
import NewEntity from "../misc/NewEntity";


export default function Keys(props: {
    update: number, // Increment to reload the list of entities
    setPrimaryView: (pv: EntityType) => void,
    setSecondaryView: (sv: SecondaryView) => void,
    globalFilters: GlobalFilters,
    viewMode: ViewMode
}) {
    const {t} = useTranslation();
    const [keys, setKeys] = useState<Array<ExtendedKey>>([]);
    const [logKeySelected, setLogKeySelected] = useState<number | undefined>();
    const [selectedToDelete, setSelectedToDelete] = useState<number[]>([]);

    const firstRun = useRef(true); // Prevent executing useEffect[props.update] on first render

    useEffect(() => {
        getAPIFilteredKeys();
    }, []);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return
        }
        getAPIFilteredKeys();
    }, [props.update]);


    const getAPIFilteredKeys = () => {
        // Prepare filter
        var filter: Record<string, any> = {}
        if (props.globalFilters.key){
            filter.id = props.globalFilters.key;
        }
        if (props.globalFilters.stay){
            filter.idstay = props.globalFilters.stay;
        }
        if (props.globalFilters.room){
            filter.idroom = props.globalFilters.room;
        }
        if (props.globalFilters.device){
            filter.iddevice = props.globalFilters.device;
        }
        Api.searchKey(filter).then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setKeys(value.content.sort((a:Key, b:Key) => (a.idkey??0) < (b.idkey??0) && 1 || -1));
                ExtendedEntitiesFactory.getExtendedKeys(value.content).then(extendedKeys => {
                    setKeys(extendedKeys);
                });
            }
        });
    }
    const getFilteredKeys = () => {
        return keys
        //     .filter((key: Key) => !props.globalFilters.key || key.idkey === props.globalFilters.key)
            .filter((key: Key) => props.globalFilters.keyIncludeExpired || !Utils.expiredTimestamp(key.nmk_dateto))
        //     .filter((key: Key) => !props.globalFilters.device || key.device_iddevice === props.globalFilters.device)
            .filter((key: Key) => !props.globalFilters.keyType || getKeyType(key)?.id === props.globalFilters.keyType)
        //    .filter((key: ExtendedKey) => !props.globalFilters.room || key.ex_room?.idroom === props.globalFilters.room)
        //     .filter((key: ExtendedKey) => !props.globalFilters.stay || key.info_idstay === props.globalFilters.stay)
            ;
    }

    const goToDevices = (deviceId: number | undefined) => {
        if (deviceId) {
            props.setPrimaryView('device');
            props.globalFilters.setDevice(deviceId);
            props.globalFilters.setRoom(0);
        }
    }

    const goToRoom = (roomId: number | undefined) => {
        if (roomId) {
            props.setPrimaryView('room');
            props.globalFilters.setRoom(roomId);
            props.globalFilters.setComplex(0);
        }
    }

    const goToStay = (stayId: number | undefined) => {
        if (stayId) {
            props.setPrimaryView('stay');
            props.globalFilters.setStay(stayId);
            props.globalFilters.setRoom(0);
        }
    }

    const toggleDelete = (keyId: number | undefined) => {
        if (!keyId) {
            return;
        }
        if (selectedToDelete.includes(keyId)) {
            setSelectedToDelete(selectedToDelete.filter(i => i !== keyId));
        } else {
            setSelectedToDelete([...selectedToDelete, keyId]);
        }
    };

    const deleteKeys = () => {
        Api.deleteKeys(selectedToDelete).then((values: any) => {
            for (let value of values) {
                Api.isError(value);
            }
            setSelectedToDelete([]);
            getAPIFilteredKeys();
        })
    };

    const getEntitiesContainer = () => {
        if (props.viewMode === 'listMode') {
            return <div className={'tableContainer'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        {/* <th>{t('id')}</th> */}
                        <th>{t('key.type')}</th>
                        <th>{t('entity.device')}</th>
                        <th>{t('entity.room')}</th>
                        <th>{t('entity.stay')}</th>
                        <th>{t('time.from')}</th>
                        <th>{t('time.to')}</th>
                        <th>{t('key.lastUse')}</th>
                        <th>{t('key.timesUsed')}</th>
                        <NewEntity entity={'key'} onClick={() => props.setSecondaryView({entity: 'key', id: 0})}
                                    display={'table'}/>
                    </tr>
                    </thead>
                    <tbody>
                    {getFilteredKeys()
                        .map(key => <tr
                            className={selectedToDelete.includes(key.idkey ?? 0) ? 'rowSelectedToDelete' : ''}>
                            {/* <td>{key.idkey}</td> */}
                            <td title={key.idkey + (getKeyType(key)?.id === KEYTYPE.NFC ? " - " + key.nmk_key_str : '')}>
                                {getKeyType(key)?.icon} {getKeyTypeI18N(t, key)}
                            </td>
                            <td>
                                <ConditionalLink
                                    content={key.device_nmk_description ?? key.device_iddevice ?? t('none')}
                                    onClick={() => goToDevices(key.device_iddevice)} appearance={'badge'}/>
                            </td>
                            <td>
                                <ConditionalLink content={key.ex_room?.name ?? t('none', {context: 'female'})}
                                                onClick={key.ex_room?.name ? () => goToRoom(key.ex_room?.idroom) : null}
                                                appearance={'badge'}/>
                            </td>
                            <td>
                                <ConditionalLink
                                    content={key.info_idstay ?? t('none', {context: 'female'})}
                                    onClick={key.info_idstay ? () => goToStay(key.info_idstay) : null}
                                    appearance={'badge'}/>
                            </td>
                            <td>{Utils.dateTimeFormatter(key.nmk_datefrom)}</td>
                            <td>{Utils.dateTimeFormatter(key.nmk_dateto)}</td>
                            <td title={Utils.dateTimeFormatter(key.last_used)}>{Utils.getRelativeTime(key.last_used)}</td>
                            <td>{key.times_used ?? 0}</td>
                            <td>
                                <div className="btn-group btn-group-sm">
                                    <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                            onClick={() => props.setSecondaryView({entity: 'key', id: key.idkey})}>
                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    </button>
                                    <button className="btn btn-sm btn-info"
                                            title={t('actions.see') + ' ' + t('entity.log').toLowerCase()}
                                            onClick={() => setLogKeySelected(key.idkey)}>
                                        <FontAwesomeIcon icon={['fas', 'history']}/>
                                    </button>
                                    <button
                                        className={'btn btn-sm btn-danger ' + (selectedToDelete.includes(key.idkey ?? 0) ? 'active' : '')}
                                        title={selectedToDelete.includes(key.idkey ?? 0) ? t('actions.unmark') : t('actions.markToDelete')}
                                        onClick={() => toggleDelete(key.idkey)}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </button>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>;
        } else {
            return <div className="cardsContainer">
                <NewEntity entity={'key'} onClick={() => props.setSecondaryView({entity: 'key', id: 0})}
                            display={'card'}/>
                {getFilteredKeys()
                    .map(key => <div
                        className={'card ' + (selectedToDelete.includes(key.idkey ?? 0) ? 'cardSelectedToDelete' : '')}
                        key={key.idkey}>
                        <div className="card-header">
                            <EntityImage entityType="key" entity={key} shape="landscape73"/>
                        </div>
                        <div className="card-body">
                            <div className="cardTitle">
                                {getKeyName(key)}
                            </div>
                            <table className="entityDataTable">
                                <tbody>
                                <tr>
                                    <td>{t('id')}</td>
                                    <td>{key.idkey}</td>
                                </tr>
                                <tr>
                                    <td>{t('key.type')}</td>
                                    <td>{getKeyType(key)?.icon} {getKeyTypeI18N(t, key)}</td>
                                </tr>
                                {
                                    getKeyType(key)?.id === KEYTYPE.NFC ?
                                        <tr>
                                            <td>{t('key.nfcKey')}</td>
                                            <td title={key.nmk_key_str}>{key.nmk_key_str}</td>
                                        </tr> : null
                                }
                                <tr>
                                    <td>{t('entity.device')}</td>
                                    <td>
                                        <ConditionalLink
                                            content={key.device_nmk_description ?? key.device_iddevice ?? t('none')}
                                            onClick={() => goToDevices(key.device_iddevice)} appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('entity.room')}</td>
                                    <td>
                                        <ConditionalLink content={key.ex_room?.name ?? t('none', {context: 'female'})}
                                                        onClick={key.ex_room?.name ? () => goToRoom(key.ex_room?.idroom) : null}
                                                        appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('entity.stay')}</td>
                                    <td>
                                        <ConditionalLink
                                            content={key.info_idstay ?? t('none', {context: 'female'})}
                                            onClick={key.info_idstay ? () => goToStay(key.info_idstay) : null}
                                            appearance={'badge'}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td>{t('time.from')}</td>
                                    <td>{Utils.dateTimeFormatter(key.nmk_datefrom)}</td>
                                </tr>
                                <tr>
                                    <td>{t('time.to')}</td>
                                    <td>{Utils.dateTimeFormatter(key.nmk_dateto)}</td>
                                </tr>
                                <tr>
                                    <td>{t('key.lastUse')}</td>
                                    <td title={Utils.dateTimeFormatter(key.last_used)}>{Utils.getRelativeTime(key.last_used)}</td>
                                </tr>
                                <tr>
                                    <td>{t('key.timesUsed')}</td>
                                    <td>{key.times_used ?? 0}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer">
                            <div className="btn-group btn-group-sm">
                                <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                        onClick={() => props.setSecondaryView({entity: 'key', id: key.idkey})}>
                                    <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    <span>{t('actions.edit')}</span>
                                </button>
                                <button className="btn btn-sm btn-info"
                                        title={t('actions.see') + ' ' + t('entity.log').toLowerCase()}
                                        onClick={() => setLogKeySelected(key.idkey)}>
                                    <FontAwesomeIcon icon={['fas', 'history']}/>
                                </button>
                                <button
                                    className={'btn btn-sm btn-danger ' + (selectedToDelete.includes(key.idkey ?? 0) ? 'active' : '')}
                                    title={selectedToDelete.includes(key.idkey ?? 0) ? t('actions.unmark') : t('actions.markToDelete')}
                                    onClick={() => toggleDelete(key.idkey)}>
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                </button>
                            </div>
                        </div>
                    </div>)}
            </div>;
        }
    }

    return <div className="primaryViewContainer">
        <ConfirmDeleteBox entityType={'key'} listOfSelectedEntities={selectedToDelete}
                            onCancel={() => setSelectedToDelete([])} onClickDeleteButton={() => deleteKeys()}/>
        <div className={'entitiesContainer'}>{getEntitiesContainer()}</div>
        <LogModal key={logKeySelected} deviceId={undefined} keyId={logKeySelected}
                    onClose={() => setLogKeySelected(undefined)}
                    title={t('entity.log') + ' ' + t('of').toLowerCase() + ' ' + (getKeyName(keys.find(k => k.idkey === logKeySelected)))}/>
    </div>;
}
