import React from 'react';
//AMC: warning removal ->import {useTranslation} from "react-i18next";
import "./ConditionalLink.scss";

/**
 * Render a badge if it has content and onClick props.
 * Render props.content without badge if no prop onClick found.
 * Render none if all props are undefined
 * @param props
 * @constructor
 */
export default function ConditionalLink(props: {
    content: string | number | undefined | null | object
    onClick?: Function | undefined | null,
    title?: string
    appearance: 'link' | 'badge',
}) {
//AMC: warning removal ->    const {t} = useTranslation();

    return props.content !== null && props.content !== undefined && props.onClick ?

        <div className={'conditionalLink'} title={props.title}
             onClick={() => props.onClick ? props.onClick() : null}>
            {props.content}
        </div>

        : ((props.content !== null && props.content !== undefined) ?

            <span title={props.title}>{props.content}</span>

            : null);
}

