import Particles from "react-tsparticles";
import {Component} from "react";

export class BackgroundParticles extends Component {
    // constructor(props: {} | Readonly<{}>) {
    //     super(props);
    //     // this.particlesInit = this.particlesInit.bind(this);
    //     // this.particlesLoaded = this.particlesLoaded.bind(this);
    // }
    // particlesInit(main: any) {
    //     console.log(main);
    //
    //     // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
    // }

    // particlesLoaded(container: any) {
    //     console.log(container);
    // }

    render() {
        return (
            <Particles
                id="tsparticles"
                // init={this.particlesInit}
                // loaded={this.particlesLoaded}
                // Options generated by https://particles.js.org/
                // I removed all false/unabled featured json sub-objects
                options={{
                    "autoPlay": true,
                    "background": {
                        "color": {
                            "value": "#f8f8f8"
                        },
                        "image": "",
                        "position": "",
                        "repeat": "",
                        "size": "",
                        "opacity": 1
                    },
                    "fullScreen": {
                        "enable": true,
                        "zIndex": -1
                    },
                    "detectRetina": true,
                    "fpsLimit": 15,
                    "particles": {
                        "color": {
                            "value": "#000000",
                        },
                        "move": {
                            "angle": {
                                "offset": 45,
                                "value": 90
                            },
                            "decay": 0,
                            "distance": 0,
                            "direction": "top",
                            "drift": 0,
                            "enable": true,
                            "outModes": {
                                "default": "out",
                                "bottom": "out",
                                "left": "out",
                                "right": "out",
                                "top": "out"
                            },
                            "random": false,
                            "size": true,
                            "speed": 0.5,
                            "straight": true,
                            "vibrate": false,
                            "warp": true
                        },
                        "number": {
                            "density": {
                                "enable": true,
                                "area": 800,
                                "factor": 1000
                            },
                            "limit": 0,
                            "value": 50
                        },
                        "opacity": {
                            "random": {
                                "enable": true,
                                "minimumValue": 0
                            },
                            "value": {
                                "min": 0,
                                "max": 0.02
                            },
                        },
                        "reduceDuplicates": true,
                        "shape": {
                            "type": "square"
                        },
                        "size": {
                            "random": {
                                "enable": true,
                                "minimumValue": 1
                            },
                            "value": {
                                "min": 1,
                                "max": 60
                            },
                        }
                    },
                    "pauseOnBlur": true,
                    "pauseOnOutsideViewport": true,
                    "responsive": [],
                    "themes": []
                }}
            />
        );
    }
}
