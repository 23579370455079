import React, {useEffect, useState} from 'react';
//AMC: warning removal ->import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
import {BasicEntity} from "../../types/BasicEntity";

export default function SelectPromise(props: {
    apiPromise: () => Promise<any>,
    idName: string,
    nameName: string,
    onChange: (value: number) => any,
    value: number,
    defaultValue: string,
    labelName: string,
    isSmall?: boolean
}) {
//AMC: warning removal ->    const {t} = useTranslation();

    const [data, setData] = useState<BasicEntity[]>([]);

    useEffect(() => {
        props.apiPromise().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                let dataList: BasicEntity[] = value.content.map((item: any) => {
                    return {id: item[props.idName], name: item[props.nameName]}
                });
                if (!dataList.find(d => d.id === props.value)) {
                    props.onChange(0);
                }
                setData(dataList);
            }
        });
    }, []);

    return <div className={'input-group ' + (props.isSmall !== undefined && props.isSmall ? 'input-group-sm ' : ' ') + (props.value !== 0 ? 'borderDance ' : ' ')}>
        <label className="input-group-text">{props.labelName}</label>
        <select className={'form-select ' + (props.isSmall !== undefined && props.isSmall ? 'form-select-sm' : '')} value={props.value}
                onChange={(event) => props.onChange(parseInt(event.target.value))}>
            <option value="0">{props.defaultValue}</option>
            {
                data.map(value => {
                    return <option value={value.id} key={value.id}>{value.name}</option>
                })
            }
        </select>
    </div>;
}

