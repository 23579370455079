import React, {ChangeEvent} from 'react';
import {useTranslation} from "react-i18next";
//AMC: warning removal ->import {getKeyType, KEYTYPE} from "../../types/KeyType";

export default function NfcInput(props: {
    value: string,
    id: string,
    onChange: (value: ChangeEvent<HTMLInputElement>) => any,
    disabled?: boolean,
    placeholder?: string,
    className?: string
}) {
    const {t} = useTranslation();

    const showNFCKeyError = () => {
        if (!props.value) {
            return false;
        }
        const nfcArray = props.value?.split(',');
        let isError: boolean = false;
        if (!nfcArray || !nfcArray.length || (nfcArray.length !== 4 && nfcArray.length !== 7)) {
            isError = true;
        }
        for (let code of nfcArray ?? []) {
            if (!code) {
                isError = true;
                continue;
            }
            if (!(new RegExp('^[0-9]+$')).test(code)) {
                isError = true;
                continue;
            }
            if (parseInt(code) < 0 || parseInt(code) > 255) {
                isError = true;
            }
        }
        return isError;
    }

    return <input id={props.id} className={'form-control ' + (showNFCKeyError() ? 'is-invalid ' : ' ') + props.className}
                  value={props.value ?? ''} disabled={props.disabled}
                  placeholder={props.placeholder ?? '#,#,#,# ' + t('or').toLowerCase() + ' #,#,#,#,#,#,#'}
                  onChange={(event) => props.onChange(event)}/>;
}

