import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import "./Modal.scss";
import "./ModalKeyQR.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QRCode from "qrcode.react";
import Modal from "./Modal";
import Api from "../../api/Api";
import {Key} from "../../api/generated/Entities";
import {getKeyName} from "../../types/KeyName";
import {getUrlNomorepassGetShared} from "../../types/KeyType";

export default function ModalKeyQR(props: {
    qrCode: string | undefined,
    onClose: Function,
    keyObject: Key,
    remainingModals?: number,
    defaultEmail?: string
}) {
    const [sendToEmail, setSendToEmail] = useState<boolean>(false);
    const [targetEmail, setTargetEmail] = useState<string>('');
    
    const {t} = useTranslation();

    const [isQREmailSend, setQREmailSend] = useState<boolean>(false);

    const validateEmail = (email: string): boolean => {
        const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regExp.test(email);
    }

    const showEmailError = (email: string): boolean => email !== '' && !validateEmail(email);


    useEffect(() => {
        if (!props.qrCode) {
            Api.pingQRClearIntervals();
            return;
        }

        // Get ticket from props.qrCode
        let ticket = props.qrCode;
        ticket = ticket.replace('nomorepass://SENDPASS', ''); // Remote and QR keys (user keys)
        ticket = ticket.replace('nomorekeys://SENDPASS', ''); // Remote and QR keys (user keys)
        ticket = ticket.replace('nomorekeys://SOUNDKEY', ''); // Sound key (key type)
        ticket = ticket.replace('nomorekeys://LIGHTKEY', ''); // Light key (key type)
        ticket = ticket.replace('nomorekeys://BLEKEY', '');   // BLE key (key type)
        ticket = ticket.replace('nomorekeys://KEY', '');   // GENERIC key (key type)
        ticket = ticket.substr(12, 20);
        console.log("ticket: "+ ticket);
        Api.pingQR(ticket).then(() => {
            close();
        });
    }, [props.qrCode]);

    const close = () => {
        Api.pingQRClearIntervals();
        props.onClose();
    }

    const doSendEmail = async () => {
        if (!props.keyObject){
            alert(t('errors.noData'));
            return;
        }
        if (!props.qrCode) {
            alert(t('errors.noData'));
            return;
        }
        let url = getUrlNomorepassGetShared(props.qrCode);
        let sendResponse = await Api.sendKeyByEmail((props.keyObject.idkey ?? 0), targetEmail, url);
        if (Api.isError(sendResponse, undefined, false)){
            alert('Error sending email');
            return;
        }
        alert(t('stay.emailsSent') + ':' + sendResponse.content.sent_ok);
        close();

    }

    const getModalTitle = () => {
        let title = getKeyName(props.keyObject);
        if (props.defaultEmail) {
            title += ' ' + t('for').toLowerCase() + ' ' + props.defaultEmail;
        }
        if (props.remainingModals !== undefined) {
            title += ' (' + props.remainingModals + ' ' +
                t('entity.key', {count: props.remainingModals}).toLowerCase() + ' ' +
                t('remaining', {count: props.remainingModals}).toLowerCase() + ')';
        }
        return title;
    }

    return <Modal visible={!!props.qrCode} title={getModalTitle()}>
        <div className="modalQrContainer">
            {t('key.modalQRTip')}
            {props.qrCode ? <QRCode key={props.qrCode} value={props.qrCode} size={440} fgColor={'#333333'} level={'H'}/> : null}
            <div className="modalQrButtons">
                <button className="btn btn-primary" onClick={() => close()}>
                    <FontAwesomeIcon icon={['fas', 'check']}/>
                    <span>
                            {
                                !isQREmailSend ? t('key.modalButtonAlreadyScanned')
                                    : t('key.modalButtonAlreadyEmailed')
                            }
                        </span>
                </button>
                <button className={'btn ' + (!isQREmailSend ? 'btn-primary' : 'btn-secondary')}
                        onClick={() => setSendToEmail(!sendToEmail)}>
                    <FontAwesomeIcon icon={['fas', 'envelope-open-text']}/>
                    <span>{t('key.modalButtonSendEmail')}</span>
                </button>
            </div>
            <div id="sendToEmailDiv" className={'mb-3 input-group ' + (sendToEmail && !isQREmailSend ? '' : 'd-none')}>
                <input className={'form-control-sm ' + (showEmailError(targetEmail) ? 'is-invalid' : '')}
                    id="sendToEmailInput" value={targetEmail ?? ''}
                    onChange={(event) => setTargetEmail(event.target.value)}
                    placeholder={t('placeholders.email')}/>&nbsp;&nbsp;
                <button className='btn btn-primary'
                    onClick={()=> doSendEmail()}
                    >
                    <FontAwesomeIcon icon={['fas', 'paper-plane']}/>
                    <span>{t('placeholders.send')}</span>

                </button>&nbsp;
                <button className='btn btn-secondary'
                    onClick={() => setSendToEmail(false)}
                    >
                    <span>{t('actions.cancel')}</span>

                </button>
            </div>
        </div>
    </Modal>;
}

