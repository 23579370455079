import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import Api from "../../api/Api";
//AMC: warning removal ->import Utils from "../../Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EntityType} from "../../types/EntityType";
import {SecondaryView} from "../../types/SecondaryView";
import {ExtendedComplex, ExtendedEntitiesFactory} from "../../api/ExtendedEntities";
import {Complex} from "../../api/generated/Entities";
import EntityImage from "../misc/EntityImage";
import ConfirmDeleteBox from "../misc/ConfirmDeleteBox";
import ConditionalLink from "../misc/ConditionalLink";
import {GlobalFilters} from "../Panel";
import {ViewMode} from "../../types/PrimaryView";
import NewEntity from "../misc/NewEntity";


export default function Complexes(props: {
    update: number, // Increment to reload the list of entities
    setPrimaryView: (pv: EntityType) => void,
    setSecondaryView: (sv: SecondaryView) => void,
    globalFilters: GlobalFilters,
    viewMode: ViewMode
}) {
    const {t} = useTranslation();
    const [complexes, setComplexes] = useState<Array<ExtendedComplex>>([]);
    const [selectedToDelete, setSelectedToDelete] = useState<number[]>([]);

    const firstRun = useRef(true); // Prevent executing useEffect[props.update] on first render

    useEffect(() => {
        getComplexes();
    }, []);

    useEffect(() => {
        if (firstRun.current) {
            firstRun.current = false;
            return
        }
        getComplexes();
    }, [props.update]);

    const getComplexes = () => {
        Api.searchComplex().then(value => {
            if (!Api.isError(value, 'mustBeArray')) {
                setComplexes(value.content);
                ExtendedEntitiesFactory.getExtendedComplexes(value.content).then(extendedComplexes => {
                    setComplexes(extendedComplexes);
                });
            }
        });
    }

    const getFilteredComplexes = () => {
        return complexes
            .filter((complex: Complex) => !props.globalFilters.complex || complex.idcomplex === props.globalFilters.complex)
    }

    const goToRooms = (complexId: number | undefined) => {
        if (complexId) {
            props.setPrimaryView('room');
            props.globalFilters.setRoom(0);
            props.globalFilters.setComplex(complexId);
        }
    }

    const toggleDelete = (stayId: number | undefined) => {
        if (!stayId) {
            return;
        }
        if (selectedToDelete.includes(stayId)) {
            setSelectedToDelete(selectedToDelete.filter(i => i !== stayId));
        } else {
            setSelectedToDelete([...selectedToDelete, stayId]);
        }
    };

    const deleteComplexes = () => {
        Api.deleteComplexes(selectedToDelete).then((values: any) => {
            for (let value of values) {
                Api.isError(value);
            }
            setSelectedToDelete([]);
            getComplexes();
        })
    };


    const getEntitiesContainer = () => {
        if (props.viewMode === 'listMode') {
            return <div className={'tableContainer'}>
                <table className={'table table-hover'}>
                    <thead>
                    <tr>
                        <th>{t('id')}</th>
                        <th>{t('name')}</th>
                        <td>{t('entity.room', {context: 'plural'})}</td>
                        <NewEntity entity={'complex'} onClick={() => props.setSecondaryView({entity: 'complex', id: 0})}
                                   display={'table'}/>
                    </tr>
                    </thead>
                    <tbody>
                    {getFilteredComplexes()
                        .map(complex => <tr
                            className={selectedToDelete.includes(complex.idcomplex ?? 0) ? 'rowSelectedToDelete' : ''}>
                            <td>{complex.idcomplex}</td>
                            <td>{complex.name}</td>
                            <td>
                                <ConditionalLink content={complex.ex_rooms?.length} appearance={'badge'}
                                                 onClick={complex.ex_rooms?.length ? () => goToRooms(complex.idcomplex) : null}/>
                            </td>
                            <td>
                                <div className="btn-group btn-group-sm">
                                    <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                            onClick={() => props.setSecondaryView({
                                                entity: 'complex',
                                                id: complex.idcomplex
                                            })}>
                                        <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                        <span>{t('actions.edit')}</span>
                                    </button>
                                    <button className="btn btn-sm btn-danger" title={t('actions.delete')}
                                            onClick={() => toggleDelete(complex.idcomplex)}>
                                        <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                    </button>
                                </div>
                            </td>
                        </tr>)}
                    </tbody>
                </table>
            </div>;
        } else {
            return <div className="cardsContainer">
                <NewEntity entity={'complex'} onClick={() => props.setSecondaryView({entity: 'complex', id: 0})}
                           display={'card'}/>
                {getFilteredComplexes()
                    .map(complex => <div
                        className={'card ' + (selectedToDelete.includes(complex.idcomplex ?? 0) ? 'cardSelectedToDelete' : '')}
                        key={complex.idcomplex}>
                        <div className="card-header">
                            <EntityImage entityType="complex" entity={complex} shape="landscape73"
                                         customImage={complex.image_filename}/>
                        </div>
                        <div className="card-body">
                            <div className="cardTitle">
                                {complex.name}
                            </div>
                            <table className="entityDataTable">
                                <tbody>
                                <tr>
                                    <td>{t('id')}</td>
                                    <td>{complex.idcomplex}</td>
                                </tr>
                                <tr>
                                    <td>{t('entity.room', {context: 'plural'})}</td>
                                    <td>
                                        <ConditionalLink content={complex.ex_rooms?.length} appearance={'badge'}
                                                         onClick={complex.ex_rooms?.length ? () => goToRooms(complex.idcomplex) : null}/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer">
                            <div className="btn-group btn-group-sm">
                                <button className="btn btn-sm btn-primary" title={t('actions.edit')}
                                        onClick={() => props.setSecondaryView({
                                            entity: 'complex',
                                            id: complex.idcomplex
                                        })}>
                                    <FontAwesomeIcon icon={['fas', 'pencil-alt']}/>
                                    <span>{t('actions.edit')}</span>
                                </button>
                                <button className="btn btn-sm btn-danger" title={t('actions.delete')}
                                        onClick={() => toggleDelete(complex.idcomplex)}>
                                    <FontAwesomeIcon icon={['fas', 'trash-alt']}/>
                                </button>
                            </div>
                        </div>
                    </div>)}
            </div>;
        }
    }

    return <div className="primaryViewContainer">
        <ConfirmDeleteBox entityType={'complex'} listOfSelectedEntities={selectedToDelete}
                          onCancel={() => setSelectedToDelete([])} onClickDeleteButton={() => deleteComplexes()}/>
        <div className={'entitiesContainer'}>{getEntitiesContainer()}</div>
    </div>;
}

