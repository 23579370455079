import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import "./LoadingSpinner.scss";

/**
 * Pass null if you want to use browser geolocation GPS
 */
export type Geolocation = {
    lat: number,
    lon: number,
    rad: number
} | null;

export default function GeolocationMap(props: { geolocation: Geolocation, onChange: (geolocation: Geolocation) => any, readonly: boolean }) {
    const {t} = useTranslation();

    const [isPreciseInputsVisible, setPreciseInputsVisible] = useState<boolean>(localStorage.getItem('isGeolocationMapPreciseInputsVisible') === 'true');

    let map = useRef<google.maps.Map>();
    let mapCircle = useRef<google.maps.Circle>();

    // Called on component load
    useEffect(() => {
        console.log('=============================');
        console.log('GeolocationMap component load');
        console.log('=============================');
        initMap();
    }, []);

    // Only called by initMap
    useEffect(() => {
        if (props.geolocation && map.current === undefined) {
            initMap();
        }
    }, [props.geolocation]);

    useEffect(() => {
        localStorage.setItem('isGeolocationMapPreciseInputsVisible', isPreciseInputsVisible ? 'true' : 'false')
    }, [isPreciseInputsVisible]);

    const initMap = () => {
        console.log('Try starting map with the given geolocation:', props.geolocation);

        // Set default location and reload if it is null
        if (props.geolocation === null) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
                    console.log('Getting browser geo...', position.coords);
                    props.onChange({lat: position.coords.latitude, lon: position.coords.longitude, rad: 1000});
                    // onchange triggers useEffect of [props.geolocation], that try initMap again
                });
            } else {
                console.log('No browser geo, getting Biblioeteca default location...');
                props.onChange({lat: 40.3487399, lon: -3.8074429, rad: 1000});
                // onchange triggers useEffect of [props.geolocation], that try initMap again
            }
            return;
        }

        // props.geolocation !== null
        // props.geolocation can come from backend, from browser location or from Biblioeteca default location
        const geolocationCenter = {lat: props.geolocation.lat, lng: props.geolocation.lon}
        const geolocationRadius = props.geolocation.rad;
        map.current = new google.maps.Map(document.getElementById('mapContainer')!, {
            center: geolocationCenter,
            zoom: 16,
            gestureHandling: "cooperative", // User may press Ctrl to zoom the map
            mapTypeControl: false,
            streetViewControl: false,
        });
        let primaryColor = getComputedStyle(document.body).getPropertyValue('--bs-primary');
        mapCircle.current = new google.maps.Circle({
            strokeColor: primaryColor,
            strokeOpacity: 1,
            strokeWeight: 1,
            fillColor: primaryColor,
            fillOpacity: 0.35,
            map: map.current,
            center: geolocationCenter,
            radius: geolocationRadius,
            editable: !props.readonly,
            draggable: false,
        });

        // Center view exactly including the radius
        map.current?.fitBounds(mapCircle.current!.getBounds()!, 50);

        // Add event listeners:
        if (!props.readonly) {
            google.maps.event.addListener(map.current, 'click', function (e: any) {
                console.log('Updating center position')
                props.onChange({
                    lat: e.latLng?.lat(),
                    lon: e.latLng?.lng(),
                    rad: Math.round(mapCircle.current!.getRadius()) ?? geolocationRadius
                });
                mapCircle.current!.setCenter(e.latLng);
            });
            google.maps.event.addListener(mapCircle.current, 'radius_changed', () => {
                console.log('Updating radius')
                props.onChange({
                    lat: mapCircle.current!.getCenter()!.lat(),
                    lon: mapCircle.current!.getCenter()!.lng(),
                    rad: Math.round(mapCircle.current!.getRadius())
                });
            });
            google.maps.event.addListener(mapCircle.current, 'center_changed', () => {
                console.log('Updating center position')
                props.onChange({
                    lat: mapCircle.current!.getCenter()!.lat(),
                    lon: mapCircle.current!.getCenter()!.lng(),
                    rad: Math.round(mapCircle.current!.getRadius())
                });
            });
        }
        console.log('Map loaded!');
    }

    const updateMapCenter = (lat: number | string | undefined, lon: number | string | undefined) => {
        if (map.current && mapCircle.current) {
            let newCenter = {lat: mapCircle.current!.getCenter()!.lat(), lng: mapCircle.current!.getCenter()!.lng()};
            if (lat !== undefined) {
                if (typeof lat === 'string') {
                    lat = parseFloat(lat);
                }
                if (!isNaN(lat)) {
                    newCenter.lat = lat;
                }
            }
            if (lon !== undefined) {
                if (typeof lon === 'string') {
                    lon = parseFloat(lon);
                }
                if (!isNaN(lon)) {
                    newCenter.lng = lon;
                }
            }
            // Set new center:
            mapCircle.current.setCenter(newCenter);
            // Map event listeners updates the geolocation state

            // Center view
            map.current.panTo(mapCircle.current.getCenter()!);
        }
    }

    const updateMapRadius = (rad: number | string | undefined) => {
        if (map.current && mapCircle.current && rad !== undefined) {
            if (typeof rad === 'string') {
                rad = parseFloat(rad);
            }
            if (!isNaN(rad)) {
                mapCircle.current.setRadius(rad);
            }
            // Map event listeners updates the geolocation state

            // Center view
            map.current.panTo(mapCircle.current.getCenter()!);
        }
    }

    // TODO: Due to all implemented styles, move styles to an scss file
    return <div>
        <div id="mapContainer"
             style={{
                 height: '364px',
                 border: '1px solid #ced4da',
                 borderRadius: isPreciseInputsVisible ? '0.25rem 0.25rem 0 0' : '0.25rem 0.25rem 0.25rem 0'
             }}/>
        <div style={{
            transition: 'height 0.25s, padding 0.25s',
            height: isPreciseInputsVisible ? '270px' : '0px',
            overflow: 'hidden',
            borderLeft: '1px solid rgb(206, 212, 218)',
            borderRight: '1px solid rgb(206, 212, 218)',
            boxShadow: 'inset 0 -1px 0 0 rgb(206 212 218)', // Border bottom
            borderRadius: '0 0 0.25rem 0px',
            padding: isPreciseInputsVisible ? '10px' : '0'
        }}>
            <div className={'mb-3'}>
                <label className="form-label" htmlFor="lat">{t('geolocation.lat')}</label>
                <input className="form-control" id="lat" type="number" step="0.00005"
                       value={props.geolocation?.lat}
                       disabled={props.readonly}
                       onChange={(event) => updateMapCenter(event.target.value, undefined)}/>
            </div>
            <div className={'mb-3'}>
                <label className="form-label" htmlFor="lon">{t('geolocation.lon')}</label>
                <input className="form-control" id="lon" type="number" step="0.00005"
                       value={props.geolocation?.lon}
                       disabled={props.readonly}
                       onChange={(event) => updateMapCenter(undefined, event.target.value)}/>
            </div>
            <div className={'mb-3'}>
                <label className="form-label" htmlFor="rad">{t('geolocation.rad')}</label>
                <input className="form-control" id="rad" type="number" value={props.geolocation?.rad}
                       disabled={props.readonly}
                       onChange={(event) => updateMapRadius(event.target.value)}/>
            </div>
        </div>
        <button className={'btn btn-sm btn-light mb-3'}
                style={{borderRadius: '0 0 0.25rem 0.25rem', border: '1px solid #ced4da', borderTop: 'none'}}
                onClick={() => setPreciseInputsVisible(!isPreciseInputsVisible)}>
            {isPreciseInputsVisible ? t('geolocation.hideInputs') : t('geolocation.showInputs')}
        </button>
    </div>;
}

